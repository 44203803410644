import axios from 'axios'
import Config from './Config'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { addMenu, deleteMenu } from '../redux/actions/MenuAction'
import { deleteUser } from '../redux/actions/UserAction'

const instance = axios.create(Config)

export const setupAxiosInterceptors = (store, dispatch) => {
  instance.interceptors.request.use(
    (config) => {
      const { auth_str, userId, userTradeRole } = store.getState().user
      if (auth_str) {
        config.headers['Authorization'] = `Bearer ${auth_str}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.data.code === 'UNAUTHORIZED') tokenExpired(dispatch)
      return Promise.reject(error)
    },
  )
}

const reloadMenu = (store, dispatch) => {
  const { userId, userTradeRole } = store.getState().user
}

const tokenExpired = (dispatch) => {
  dispatch(deleteUser())
  dispatch(deleteMenu())
  window.location.hash = '/'
  window.location.reload()
}

export default instance
