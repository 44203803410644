export const getBaseUrl = () => {
  const localAPIUrl = 'https://192.168.29.219:7185/api'
  const devAPIUrl = 'https://iwms-api-dev.equadriga.in/api'
  const uatAPIUrl = 'https://iwms-api-uat.equadriga.in/api'
  const defaultUrl = devAPIUrl //'https://gyf.equadriga.in/api' //'https://iwms-api-dev.equadriga.in/api'
  let url = process.env.REACT_APP_API_URL || defaultUrl
  return url
}

export const config = {
  baseURL: getBaseUrl(),
  version: '1.0.0',
}

export default config
