import React, { Component, useState } from "react";
import classnames from "classnames";
import "./Navber.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../../assets/images/iwms_logo_bg.jpg";
import { GrBold } from "react-icons/gr";
import { RxCheck } from "react-icons/rx";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
  FormSelect,
  ButtonGroup,
  Container,
  Button,
} from "reactstrap";
import { BsCheck } from "react-icons/bs";

import {
  initLanguage,
  translateLanguage,
  checkedLanguagesKey,
  languageEnglish,
  languageVietnamese,
  defaultLanguage,
  allLanguages,
} from "../../services/CommonUtil";

class Navber extends Component {
  constructor(props) {
    super(props);
    var locisCheckedLanguage = localStorage.getItem("isCheckedLanguage");
    if (locisCheckedLanguage === null || locisCheckedLanguage === undefined) {
      locisCheckedLanguage = defaultLanguage === languageEnglish;
      localStorage.setItem("isCheckedLanguage", locisCheckedLanguage);
    }
    var locpathname = "";
    try {
      locpathname = window.location.hash.split("?")[0];
    } catch (e) {}
    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
      isCheckedLanguage: locisCheckedLanguage === "true" ? true : false,
      pathname: locpathname,
    };
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  Fn_CheckedLanguage = () => {
    var checked = document.getElementById("rad_lang1").checked;
    this.setState({ isCheckedLanguage: checked });
    localStorage.setItem("isCheckedLanguage", checked);
    console.log(this.state.isCheckedLanguage);
    console.log(checked);
    setTimeout(function () {
      initLanguage(function () {
        window.location.reload();
      });
    }, 100);
  };
  Fn_WindowReload = () => {
    setTimeout(function () {
      window.location.reload();
    }, 0);
  };
  // Hide or show the menu.
  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    });
  };

  Fn_Logout = () => {
    localStorage.clear();

    //window.location.href = "/#/login";
    setTimeout(function () {
      window.location.href = "/";
    }, 1000);
  };
  // handleNavItemBg = (path) => {
  //   this.setState({ pathname: path });
  //   if (
  //     path === "#/WastePoints" ||
  //     path === "#/WasteTricycle" ||
  //     path=== "#/WasteTruck"
  //   ) {
  //     this.setState({ bodyBackgroundColor: "#fff" });
  //   } else {
  //     this.setState({ bodyBackgroundColor: "#d1edb5" });
  //   }
  // };
  render() {
    return (
      <div>
        <Navbar
          className="col-md-12"
          bg="primary "
          text="white"
          var
          expand="lg"
        >
          <Nav className="col-12">
            <div class="col-12 topnav d-flex">
              <Col md={5} className="d-flex">
                <Col md="6" className="menu-language-switch">
                  {allLanguages.map((language, index) => (
                    <>
                      {language.languageText +
                        (language.languageId === languageVietnamese
                          ? " | "
                          : "")}
                    </>
                  ))}
                </Col>
                <Col md="6" className="d-flex ">
                  <ButtonGroup
                    size="md"
                    className="col-12 language-switch-title"
                  >
                    {allLanguages.map((language, index) => (
                      <>
                        {language.languageId == languageEnglish ? (
                          <Button
                            color="warning"
                            className="btn-pill btn btn-warning"
                          >
                            <Input
                              id="rad_lang1"
                              type="radio"
                              name="radio2"
                              checked={this.state.isCheckedLanguage}
                              onChange={this.Fn_CheckedLanguage}
                            />
                            {this.state.isCheckedLanguage && (
                              <RxCheck className="radio_check" size="25" />
                            )}
                            <br></br>
                            {language.languageDescription}
                          </Button>
                        ) : (
                          <Button
                            color="danger"
                            className="btn-pill btn btn-danger"
                          >
                            {" "}
                            <Input
                              id="rad_lang2"
                              type="radio"
                              name="radio2"
                              checked={!this.state.isCheckedLanguage}
                              onChange={this.Fn_CheckedLanguage}
                            />
                            {!this.state.isCheckedLanguage && (
                              <RxCheck className="radio_check" size="25" />
                            )}
                            <br></br>
                            {language.languageDescription}
                          </Button>
                        )}
                      </>
                    ))}
                  </ButtonGroup>
                </Col>
              </Col>
              <Col md="3">
                <img
                  width={60}
                  height={60}
                  src={Logo}
                  alt=""
                  class="rounded mx-auto d-block"
                />
              </Col>

              <Col md={3} className="d-flex text-right"></Col>
              <Col md={1} className="d-flex text-right">
                <Button
                  className="mb-2 me-2 btn-danger fw-bold"
                  color="danger"
                  onClick={this.Fn_Logout}
                >
                  {translateLanguage(checkedLanguagesKey, "logout")}
                </Button>
              </Col>
            </div>
          </Nav>
        </Navbar>
        <Navbar
          className="text-center"
          bg="secondary"
          text="primary"
          var
          expand="lg"
        >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="col-12">
              <NavDropdown
                title={translateLanguage(checkedLanguagesKey, "dashboard")}
                id="basic-nav-dropdown"
                className="col-3 text-center"
              >
                <NavDropdown.Item href="/#/WastePoints">
                  <div className="row">
                    <div className="col-1">
                      {this.state.pathname === "#/WastePoints" ||
                      this.state.pathname === "#/WastePoints" ? (
                        <BsCheck size={25} color="white" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-11">
                      {translateLanguage(
                        checkedLanguagesKey,
                        "waste_collection_points"
                      )}
                    </div>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item href="/#/WasteTruck">
                  <div className="row">
                    <div className="col-1">
                      {this.state.pathname === "#/WasteTruck" ||
                      this.state.pathname === "#/WasteTruck" ? (
                        <BsCheck size={25} color="white" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-11">
                      {translateLanguage(
                        checkedLanguagesKey,
                        "waste_collection_trucks"
                      )}
                    </div>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item href="/#/WasteTricycle">
                  <div className="row">
                    <div className="col-1">
                      {this.state.pathname === "#/WasteTricycle" ||
                      this.state.pathname === "#/WasteTricycle" ? (
                        <BsCheck size={25} color="white" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-11">
                      {translateLanguage(
                        checkedLanguagesKey,
                        "waste_collection_tricycle"
                      )}
                    </div>
                  </div>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title={translateLanguage(
                  checkedLanguagesKey,
                  "qr_code_generation"
                )}
                id="basic-nav-dropdown"
                className="col-3 text-center"
              >
                <NavDropdown.Item
                  href="/#/address"
                  onClick={this.Fn_WindowReload}
                >
                  <div className="row">
                    <div className="col-1">
                      {this.state.pathname === "#/address" ||
                      this.state.pathname === "#/addresslist" ? (
                        <BsCheck size={25} color="white" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-11">
                      {translateLanguage(
                        checkedLanguagesKey,
                        "assigning_qr_code_for_address"
                      )}
                    </div>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/#/tricycle"
                  onClick={this.Fn_WindowReload}
                >
                  <div className="row">
                    <div className="col-1">
                      {this.state.pathname === "#/tricycle" ||
                      this.state.pathname === "#/tricyclelist" ? (
                        <BsCheck size={25} color="white" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-11">
                      {translateLanguage(
                        checkedLanguagesKey,
                        "assigning_qr_code_for_tricycle"
                      )}
                    </div>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/#/truck"
                  onClick={this.Fn_WindowReload}
                >
                  <div className="row">
                    <div className="col-1">
                      {this.state.pathname === "#/truck" ||
                      this.state.pathname === "#/trucklist" ? (
                        <BsCheck size={25} color="white" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-11">
                      {translateLanguage(
                        checkedLanguagesKey,
                        "assigning_qr_code_for_truck"
                      )}
                    </div>
                  </div>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title={translateLanguage(checkedLanguagesKey, "administration")}
                id="basic-nav-dropdown"
                className="col-3"
              >
                <NavDropdown.Item
                  href="/#/usermanagement"
                  onClick={this.Fn_WindowReload}
                >
                  <div className="row">
                    <div className="col-1">
                      {this.state.pathname === "#/usermanagement" ||
                      this.state.pathname === "#/userlist" ? (
                        <BsCheck size={25} color="white" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-11">
                      {translateLanguage(
                        checkedLanguagesKey,
                        "user_management"
                      )}
                    </div>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/#/country"
                  onClick={this.Fn_WindowReload}
                >
                  <div className="row">
                    <div className="col-1">
                      {this.state.pathname === "#/country" ||
                      this.state.pathname === "#/country" ? (
                        <BsCheck size={25} color="white" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-11">
                      {translateLanguage(checkedLanguagesKey, "country")}
                    </div>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/#/province"
                  onClick={this.Fn_WindowReload}
                >
                  <div className="row">
                    <div className="col-1">
                      {this.state.pathname === "#/province" ||
                      this.state.pathname === "#/province" ? (
                        <BsCheck size={25} color="white" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-11">
                      {translateLanguage(checkedLanguagesKey, "province")}
                    </div>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item href="/#/city" onClick={this.Fn_WindowReload}>
                  <div className="row">
                    <div className="col-1">
                      {this.state.pathname === "#/city" ||
                      this.state.pathname === "#/city" ? (
                        <BsCheck size={25} color="white" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-11">
                      {translateLanguage(checkedLanguagesKey, "city")}
                    </div>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/#/postalcode"
                  onClick={this.Fn_WindowReload}
                >
                  <div className="row">
                    <div className="col-1">
                      {this.state.pathname === "#/postalcode" ||
                      this.state.pathname === "#/postalcode" ? (
                        <BsCheck size={25} color="white" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-11">
                      {translateLanguage(checkedLanguagesKey, "postal_code")}
                    </div>
                  </div>
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link
                className="col-3"
                href="/#/analyticsreports"
                onClick={this.Fn_WindowReload}
              >
                {translateLanguage(
                  checkedLanguagesKey,
                  "analytics_and_reports"
                )}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Navber;
