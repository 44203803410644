import React, { useState, useEffect } from "react";
import Navber from "../Navber/Navber";
import { Link, useNavigate } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Table } from "react-bootstrap";
import "./QRCodes.css";
import LCPCNavbar from "../LCPCNavBar/LCPCNavbar";
// import Homeimage from "../Homeimage";
// import Footer from "../Footer";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
  FormSelect,
  ButtonGroup,
  Button,
  Container,
} from "reactstrap";
import {
  initLanguage,
  translateLanguage,
  checkedLanguagesKey,
  languageEnglish,
  languageVietnamese,
  defaultLanguage,
  printDivContent,
} from "../../services/CommonUtil";
import {
  apiRegistrationGetAllAddressRegistrationById,
  apiRegistrationGetAllAddressRegistrationsActive,
  apiRegistrationDeleteAddressRegistrations,
} from "../../services/MicroService";
import QRCode from "./QRCode";

const ts = (key) => {
  return translateLanguage(checkedLanguagesKey, key);
};

const header = [
  { title: ts("select"), prop: "select" },
  { title: ts("s_no"), prop: "sno" },
  { title: ts("unique_ID"), prop: "addressUniqueId" },
  { title: ts("collection_point"), prop: "collectionPointName" },
  { title: ts("contact_person_name"), prop: "addressContactPersonName" },
  { title: ts("UserCreation_Number"), prop: "addressContactNumber" },
  { title: ts("house_building_name"), prop: "addressBuildingName" },
  // { title: ts("type_of_address"), prop: "addressTypeName" },
  { title: ts("address"), prop: "address" },
  // { title: ts("address_line1"), prop: "addressLine1" },
  // { title: ts("address_line2"), prop: "addressLine2" },
  { title: ts("latitude_title"), prop: "addressLat" },
  { title: ts("longitude_title"), prop: "addressLong" },
];

const QRCodeList = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const navigate = useNavigate();
  const [screenTranslateLanguage, setScreenTranslateLanguage] = useState({});
  const [allAddressList, setAllAddressList] = useState([]);
  const [checkedAddressID, setCheckedAddressID] = useState(null);
  const [activeAddressQRText, setActiveAddressQRText] = useState("NO_DATA");
  const sAlert = (msg) => {
    setShowAlert(true);
    setAlertMessage(ts(msg));
  };
  const sConfirm = (msg) => {
    setShowConfirm(true);
    setConfirmMessage(ts(msg));
  };
  useEffect(() => {
    console.log("activeTricycleQRText");
    console.log(activeAddressQRText);
    if (activeAddressQRText != "NO_DATA") {
      document.getElementById("qr_code_data_display").innerHTML =
        activeAddressQRText;
      Fn_CreateAddressQRCode(activeAddressQRText);
      setTimeout(function () {
        printDivContent(activeAddressQRText);
      }, 1000);
    }
  }, [activeAddressQRText]);
  useEffect(() => {
    Fn_apiRegistrationGetAllAddressRegistrationsActive();
    initLanguage(function () {
      Fn_screenTranslateLanguageUpdate();
    });
  }, []);

  const Fn_getElementById = (id) => {
    console.log(id);
    return document.getElementById(id).value;
  };

  const Fn_setElementById = (id, value) => {
    document.getElementById(id).innerText = value;
  };

  const Fn_setElementByIdValue = (id, value) => {
    document.getElementById(id).value = value;
  };

  const Fn_validateEmpty = (value) => {
    return value == undefined || value == null || value == "";
  };

  const Fn_CheckedAddress = (e) => {
    console.log(e);
    setCheckedAddressID(e.target.value);
  };
  const Fn_screenTranslateLanguageUpdate = () => {
    setScreenTranslateLanguage({});
  };
  const Fn_apiRegistrationDeleteAddressRegistrations = (checkedAddressID) => {
    apiRegistrationDeleteAddressRegistrations(checkedAddressID)
      .then((response) => {
        console.log("apiRegistrationDeleteAddressRegistrations response");
        console.log(response);
        if (response.status == 200) {
          var dataObj = response.data;
          var content = dataObj.content;

          sAlert("record_deleted_successfully");
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((err) => {
        // setAlertmessage(err.response.data.message)
      });
  };

  const Fn_apiRegistrationGetAllAddressRegistrationsActive = () => {
    apiRegistrationGetAllAddressRegistrationsActive()
      .then((response) => {
        console.log("apiRegistrationGetAllAddressRegistrationsActive response");
        console.log(response);
        if (response.status === 200) {
          var dataObj = response.data;

          var content = dataObj.content;
          var contentLen = content.length;

          for (var i = 0; i < contentLen; i++) {
            var addressId = content[i].addressId;
            var addressType = content[i].addressType;
            var addressTypeName =
              addressType === 1
                ? translateLanguage(checkedLanguagesKey, "residential")
                : translateLanguage(checkedLanguagesKey, "commercial");

            var sno = i + 1;
            let buildingNoStr =
              content[i].addressBuildingNumber !== null &&
              content[i].addressBuildingNumber !== " "
                ? `${content[i].addressBuildingNumber},`
                : "";
            content[i]["address"] = (
              <span
                dangerouslySetInnerHTML={{
                  __html: `${buildingNoStr} ${content[i].addressLine1}<br/>${content[i].addressLine2}.`,
                }}
              />
            );
            content[i]["sno"] = i + 1;
            content[i]["select"] = (
              <Input
                id={"rad_addresstype_" + sno}
                type="radio"
                name="addresstype"
                value={addressId}
                onClick={Fn_CheckedAddress}
              />
            );
            content[i]["addressTypeName"] = addressTypeName;
          }

          setAllAddressList(content);
        }
      })
      .catch((err) => {
        // setAlertmessage(err.response.data.message)
      });
  };

  const Fn_GetAllAddressRegistrationById = (adsId) => {
    apiRegistrationGetAllAddressRegistrationById(adsId)
      .then((response) => {
        console.log("apiRegistrationGetAllAddressRegistrationById response");
        console.log(response);
        if (response.status == 200) {
          var dataObj = response.data;

          var content = dataObj.content[0];
          console.log(content);
          var qr_code_data_display = content.addressFullNumber;
          setActiveAddressQRText(qr_code_data_display);
        }
      })
      .catch((err) => {
        // setAlertmessage(err.response.data.message)
      });
  };

  const Fn_GoToCreateAddress = () => {
    navigate("/QRCode");
  };
  const Fn_CancelAddress = () => {
    Fn_setElementByIdValue("txt_house_name", "");
    document.getElementById("rad_addresstype").checked = true;
    Fn_setElementByIdValue("txt_house_no", "");
    Fn_setElementByIdValue("txt_address_l1", "");
    Fn_setElementByIdValue("txt_address_l2", "");
  };

  const Fn_ViewAddress = () => {
    if (checkedAddressID == null) {
      sAlert("kindly_select_collection_point");
      return false;
    }
    //sAlert("not_implemented");
    navigate("/QRCode?checkedAddressID=" + checkedAddressID + "&mode=view");
  };

  const Fn_EditAddress = () => {
    if (checkedAddressID == null) {
      sAlert("kindly_select_collection_point");
      return false;
    }
    navigate("/QRCode?checkedAddressID=" + checkedAddressID + "&mode=edit");
  };

  const Fn_DeleteAddress = () => {
    if (checkedAddressID == null) {
      sAlert("kindly_select_collection_point");
      return false;
    }
    Fn_apiRegistrationDeleteAddressRegistrations(checkedAddressID);
  };

  const Fn_DeleteAddress_Confirm = () => {
    Fn_DeleteAddress();
  };

  const Fn_PrintAddress = () => {
    if (checkedAddressID == null) {
      sAlert("kindly_select_collection_point");
      return false;
    }
    Fn_GetAllAddressRegistrationById(checkedAddressID);
  };

  const Fn_BackAddress = () => {};
  /* QR Code */

  const Fn_CreateAddressQRCode = (qrCodeValue) => {
    let finalURL =
      "https://chart.googleapis.com/chart?cht=qr&chl=" +
      qrCodeValue +
      "&chs=280x280&chld=L|0";

    // Replace the src of the image with
    // the QR code image
    //$("#qr_code_image_display").attr("src", finalURL);
    document
      .getElementById("qr_code_image_display")
      .setAttribute("src", finalURL);
  };

  // render() {
  return (
    <div>
      <LCPCNavbar />
      {/* <Navber /> */}
      <div md="12" className="mt-4">
      <Container fluid>
        <div id="div_qr_code_print_display" className="displayNone">
          <Row className="mt-4">
            <img id="qr_code_image_display" src="" class="qr-code"></img>
          </Row>

          <FormGroup row className="mb-low mt-3">
            <Label for="exampleFile" sm={6}>
              {translateLanguage(checkedLanguagesKey, "qr_code_no") + " : "}
            </Label>
            <Label for="exampleEmail" id="qr_code_data_display" sm={6}>
              -
            </Label>
          </FormGroup>
        </div>
        <Col xs={12}>
        <div className="p-2">
        <div className="row">
        <div className="col-md-6">
        <h4 className="bold mb-4">
                  {translateLanguage(
                    checkedLanguagesKey,
                    "qr_code_generation_list"
                  )}
                </h4>
              </div>
              <div className="col-md-6 d-flex justify-content-end align-items-center">
                <Button
                  className="mb-2 me-2 side pull-right"
                  active
                  color="primary1"
                  onClick={Fn_GoToCreateAddress}
                >
                  {translateLanguage(
                    checkedLanguagesKey,
                    "create"
                  )}
                </Button>
                <Button
                  className="mb-2 me-2 side pull-right"
                  active
                  color="info"
                  onClick={Fn_ViewAddress}
                >
                  {translateLanguage(checkedLanguagesKey, "view")}
                </Button>
                <Button
                  className="mb-2 me-2 side pull-right"
                  active
                  color="primary1"
                  onClick={Fn_EditAddress}
                >
                  {translateLanguage(checkedLanguagesKey, "edit")}
                </Button>
                <Button
                  className="mb-2 me-2 side pull-right"
                  active
                  color="info"
                  onClick={Fn_PrintAddress}
                >
                  {translateLanguage(checkedLanguagesKey, "print")}
                </Button>
                <Button
                  className="mb-2 me-2 btn-danger pull-right"
                  active
                  color="cancel1"
                  onClick={() => {
                    sConfirm("do_you_want_to_delete_the_record");
                  }}
                >
                  {translateLanguage(checkedLanguagesKey, "delete")}
                </Button>
               
                
              </div>
            </div>
            <Card className="table-card">
            <CardBody>
            <div className="home mb-2">
              <Col md={12}>
                <DatatableWrapper
                  body={allAddressList}
                  headers={header}
                  checkboxProps={{
                    onCheckboxChange: function noRefCheck() {},
                  }}
                  paginationOptionsProps={{
                    initialState: {
                      // rowsPerPage: 5,
                      // options: [5, 10, 15, 20],
                      rowsPerPage: 25,
                      options: [25, 50, 75, 100],
                    },
                  }}
                >
                  <Row>
                    <Col
                      xs={12}
                      lg={4}
                      className="d-flex flex-col justify-content-end align-items-end"
                    >
                      <Filter />
                    </Col>
                    <Col xs={12} sm={8} className="d-none">
                      <div class="pull-right">
                        <PaginationOptions />
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      lg={4}
                      className="d-flex flex-col justify-content-end align-items-end"
                    ></Col>
                  </Row>
                  <div className="table-responsive">
                  <Table>
                    <TableHeader />
                    <TableBody />
                  </Table>
                  </div>
                  <div class="pull-right">
                    <Pagination
                      labels={{
                        firstPage: "<<",
                        lastPage: ">>",
                        prevPage: "<",
                        nextPage: ">",
                      }}
                    />
                  </div>
                </DatatableWrapper>
              </Col>
            </div>
            </CardBody>
          </Card>
          </div>
        </Col>
        </Container>
      </div>
      {/* <QRCode /> */}
      {alertMessage !== "" ? (
        <SweetAlert
          title={translateLanguage(checkedLanguagesKey, "alert")}
          show={showAlert}
          confirmBtnText={ts("ok")}
          onConfirm={() => {
            setShowAlert(false);
          }}
          onCancel={() => {
            return true;
          }}
        >
          {alertMessage}
        </SweetAlert>
      ) : (
        ""
      )}
      {confirmMessage != "" ? (
        <SweetAlert
          warning
          showCancel
          show={showConfirm}
          confirmBtnText={ts("delete")}
          cancelBtnText={ts("cancel")}
          confirmBtnyle="danger"
          // title={ts("arBsSte_you_sure")}
          title={ts("alert")}
          onConfirm={() => {
            Fn_DeleteAddress_Confirm();
            setShowConfirm(false);
          }}
          onCancel={() => {
            setShowConfirm(false);
          }}
        >
          {confirmMessage}
        </SweetAlert>
      ) : (
        ""
      )}
    </div>
  );
  // }
};

export default QRCodeList;
