import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import MrfMenuNavbar from "../Navber/MrfMenuNavbar";
// import LCPCNavbar from "../LCPCNavBar/LCPCNavbar";
import MrfMenuNavbarWithoutLogin from "../Navber/MrfMenuNavbarWithoutLogin";
import "./LCPCLoginPage.css";

const MobileLCPCLoginPage = () => {
  const [emailAddress, setEmailAddress] = useState("admin@gmail.com");
  const [password, setPassword] = useState("admin");
  const navigate = useNavigate();

  const handleFormLogin = () => {
    if (emailAddress === "admin@gmail.com" && password === "admin") {
      navigate("/LCPCLanding");
    }
  };

  const handleOnChangeEmailAddress = (e) => {
    const emailID = e.target.value;
    setEmailAddress(emailID);
  };

  const handleOnChangePassword = (e) => {
    const passcode = e.target.value;
    setPassword(passcode);
  };

  return (
    <div>
      {localStorage.getItem("isLogin") === undefined ||
      localStorage.getItem("isLogin") === "false" ? (
        <MrfMenuNavbarWithoutLogin isLogin={true} />
      ) : (
        <MrfMenuNavbar isLogin={true} />
      )}
      <div className="Auth-form-container">
        <form className="Auth-form" onSubmit={handleFormLogin}>
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Sign In</h3>
            <div className="form-group mt-3">
              <label>Username</label>
              <input
                type="email"
                value={emailAddress}
                className="form-control mt-1"
                placeholder="Enter username"
                onChange={(e) => handleOnChangeEmailAddress(e)}
              />
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <input
                type="password"
                value={password}
                className="form-control mt-1"
                placeholder="Enter password"
                onChange={(e) => handleOnChangePassword(e)}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
            {/* <p className="forgot-password text-right mt-2">
              Forgot <a href="#">password?</a>
            </p> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default MobileLCPCLoginPage;
