import Api from "./Api";

export const postSample = (params) => {
  return Api.post("/public/getBearerToken", params);
};

export const getSample = (teamId) => {
  return Api.get("/dropdown/getSampleByTeamId", {
    params: {
      teamId: teamId,
    },
  });
};

export const apiUserManagementsLogin = (username, password) => {
  return Api.post(
    "/UserManagements/Login?email=" + username + "&password=" + password + "",
    {}
  );
};

export const apiConfigurationsGetAllGenericConfigurationKey = () => {
  return Api.get("Configurations/GetAllGenericConfigurationKey", {});
};

export const apiConfigurationsGetAllLanguages = () => {
  return Api.get("Configurations/GetAllLanguages", {});
};

export const apiConfigurationsGetAllLanguageKeyActive = () => {
  return Api.get("Configurations/GetAllLanguageKeyActive", {});
};

export const apiRegistrationGetAllAddressRegistrationById = (adsId) => {
  return Api.get(
    "Registration/GetAllAddressRegistrationById?adsId=" + adsId,
    {}
  );
};

export const apiRegistrationAddAddressRegistration = (params) => {
  return Api.post("Registration/AddAddressRegistration", params);
};
export const apiForgotPassword = (username, password, otp, languageId) => {
  var usernamePair = "email=" + username;
  var passwordPair = password === null ? "" : "&password=" + password;
  var otpPair = otp === null ? "" : "&otp=" + otp;
  var languageIdPair = languageId === null ? "" : "&languageId=" + languageId;
  otpPair = password === null ? otpPair : "";

  return Api.post(
    "/UserManagements/ForgotPassword?" +
      usernamePair +
      passwordPair +
      otpPair +
      languageIdPair,
    {}
  );
};
// Tricycle registration
export const apiAddTricycleRegistration = (params) => {
  return Api.post("Registration/AddTricycle", params);
};
export const apiRegistrationGetAllTricycleById = (TricycleId) => {
  return Api.get(
    "Registration/GetAllTricycleById?TricycleId=" + TricycleId,
    {}
  );
};

//Truck registration
export const apiAddTruckRegistration = (params) => {
  return Api.post("Registration/AddTruck", params);
};
export const apiRegistrationGetAllTruckById = (TruckId) => {
  return Api.get("Registration/GetAllTruckById?TruckId=" + TruckId, {});
};

export const apiConfigurationsGetAllCountries = () => {
  return Api.get("Configurations/GetAllCountries", {});
};
export const apiConfigurationsGetAllProvince = () => {
  return Api.get("Configurations/GetAllProvinces", {});
};
export const apiConfigurationsGetAllProvinceByCountryId = (countryId) => {
  return Api.get(
    "Configurations/GetAllProvinceByCountryId?countryId=" + countryId,
    {}
  );
};

export const apiConfigurationsGetAllCities = () => {
  return Api.get("Configurations/GetAllCities", {});
};

export const apiConfigurationsGetAllCityByProvinceId = (provinceId) => {
  return Api.get(
    "Configurations/GetAllCityByProvinceId?provinceId=" + provinceId,
    {}
  );
};

export const apiRegistrationGetAllPostalCodeById = (CityId) => {
  return Api.get(
    "Configurations/GetAllPostalCodeByCityId?CityId=" + CityId,
    {}
  );
};

export const apiRegistrationGetAllAddressRegistrationsActive = () => {
  return Api.get("Registration/GetAllAddressRegistrationsActive", {});
};

export const apiRegistrationUpdateAddressRegistration = (params) => {
  return Api.post("Registration/UpdateAddressRegistration", params);
};
export const apiRegistrationUpdateTricycleRegistration = (params) => {
  return Api.post("Registration/UpdateTricycle", params);
};
export const apiRegistrationUpdateTruckRegistration = (params) => {
  return Api.post("Registration/UpdateTruck", params);
};
export const apiRegistrationDeleteAddressRegistrations = (adresssId) => {
  return Api.post(
    "Registration/DeleteAddressRegistrations?adresssId=" + adresssId,
    {}
  );
};
export const apiRegistrationDeleteTricycleRegistrations = (TricycleId) => {
  return Api.post("Registration/DeleteTricycles?TricycleId=" + TricycleId, {});
};
export const apiRegistrationDeleteTruckRegistrations = (TruckId) => {
  return Api.post("Registration/DeleteTrucks?TruckId=" + TruckId, {});
};
export const apiRegistrationGetAllTricycleRegistrationsActive = () => {
  return Api.get("Registration/GetAllTricyclesActive", {});
};
export const apiRegistrationGetAllTruckRegistrationsActive = () => {
  return Api.get("Registration/GetAllTrucksActive", {});
};
export const apiRegistrationGetAllUserRegistrationsActive = () => {
  return Api.get(
    "UserManagements/GetAllUserRegistrationsActive", //Active
    {}
  );
};
export const apiRegistrationDeleteUserRegistrations = (urnId) => {
  return Api.post("UserManagements/DeleteUserRegistrations?urnId=" + urnId, {});
};
export const apiRegistrationUpdateUserRegistration = (params) => {
  return Api.post("UserManagements/UpdateUserRegistration", params);
};
export const apiUserManagementsAddUserRegistration = (params) => {
  return Api.post("UserManagements/AddUserRegistration", params);
};
export const apiRegistrationGetAllUserById = (userRegistrationId) => {
  return Api.get(
    "UserManagements/GetAllUserRegistrationById?userRegistrationId=" +
      userRegistrationId,
    {}
  );
};

export const apiUserManagementsGetAllUserRoles = () => {
  return Api.get("UserManagements/GetAllUserRoles", {});
};

export const apiConfigurationsAddCountryRegistration = (params) => {
  return Api.post("Configurations/AddCountry", params);
};
export const apiRegistrationUpdateCountryRegistration = (params) => {
  return Api.post("Configurations/UpdateCountry", params);
};
export const apiRegistrationDeleteCountryRegistrations = (cntId) => {
  return Api.post("Configurations/DeleteCountries?cntId=" + cntId, {});
};
export const apiRegistrationGetAllCountryById = (countryId) => {
  return Api.get("Configurations/GetAllCountryById?countryId=" + countryId, {});
};
export const apiRegistrationGetAllCountryRegistrationsActive = () => {
  return Api.get("Configurations/GetAllCountriesActive", {});
};

export const apiConfigurationsAddProvinceRegistration = (params) => {
  return Api.post("Configurations/AddProvince", params);
};
export const apiRegistrationUpdateProvinceRegistration = (params) => {
  return Api.post("Configurations/UpdateProvince", params);
};
export const apiRegistrationDeleteProvinceRegistrations = (pvcId) => {
  return Api.post("Configurations/DeleteProvinces?pvcId=" + pvcId, {});
};
export const apiRegistrationGetAllProvinceById = (provinceId) => {
  return Api.get(
    "Configurations/GetAllProvinceById?provinceId=" + provinceId,
    {}
  );
};
export const apiRegistrationGetAllProvinceRegistrationsActive = () => {
  return Api.get("Configurations/GetAllProvincesActive", {});
};

export const apiConfigurationsAddCityRegistration = (params) => {
  return Api.post("Configurations/AddCity", params);
};
export const apiRegistrationUpdateCityRegistration = (params) => {
  return Api.post("Configurations/UpdateCity", params);
};
export const apiRegistrationDeleteCitiesRegistrations = (ctsId) => {
  return Api.post("Configurations/DeleteCities?ctsId=" + ctsId, {});
};
export const apiRegistrationGetAllCityById = (cityId) => {
  return Api.get("Configurations/GetAllCityById?cityId=" + cityId, {});
};
export const apiRegistrationGetAllCityRegistrationsActive = () => {
  return Api.get("Configurations/GetAllCitiesActive", {});
};

export const apiConfigurationsAddPostalCodeRegistration = (params) => {
  return Api.post("Configurations/AddPostalCode", params);
};
export const apiRegistrationUpdatePostalCodeRegistration = (params) => {
  return Api.post("Configurations/UpdatePostalCode", params);
};
export const apiRegistrationDeletePostalCodeRegistrations = (postalCodeId) => {
  return Api.post(
    "Configurations/DeletePostalCodes?postalCodeId=" + postalCodeId,
    {}
  );
};
export const apiRegistrationGetAllPostalCodeByCityId = (postalCodeId) => {
  return Api.get(
    "Configurations/GetAllPostalCodeById?postalCodeId=" + postalCodeId,
    {}
  );
};
export const apiRegistrationGetAllPostalCodeRegistrationsActive = () => {
  return Api.get("Configurations/GetAllPostalCodesActive", {});
};
export const apiRegistrationGetAllCityByProvinceId = (provinceId) => {
  return Api.get(
    "Configurations/GetAllCityByProvinceId?provinceId=" + provinceId,
    {}
  );
};

export const apiMRFUserManagementsAddCollectionDetails = (params) => {
  return Api.post("MRFUserManagements/AddCollectionDetails", params);
};

export const apiMRFUserManagementsGetAllComponentType = () => {
  return Api.get("MRFUserManagements/GetAllComponentType", {});
};

export const apiMRFUserManagementsGetAllComponentByTypeId = (
  componentTypeId
) => {
  return Api.get(
    "MRFUserManagements/GetAllComponentByTypeId?componentTypeId=" +
      componentTypeId,
    {}
  );
};

export const apiMRFUserManagementsGetAllScrapAddressActive = () => {
  return Api.get("MRFUserManagements/GetAllScrapAddressActive", {});
};

export const apiMRFUserManagementsGetAllCollectionDetailsByMonth = (
  month,
  year,
  scrapId,
  ComponentDetailId,
  isWeek
) => {
  return Api.get(
    "MRFUserManagements/GetAllCollectionDetailsByMonth?month=" +
      month +
      "&year=" +
      year +
      "&scrapId=" +
      scrapId +
      "&ComponentDetailId=" +
      ComponentDetailId +
      "&isWeek=" +
      isWeek,
    {}
  );
};

// MRF UserRegistration
export const apiMRFUserManagementsGetAllTypeMrfActive = () => {
  return Api.get("MRFUserManagements/GetAllTypeOfMRF", {});
};

export const apiRegistrationAddScrapAddress = (params) => {
  return Api.post("MRFUserManagements/CreateScrapAddress", params);
};

export const apiRegistrationGetAllScrapAddress = (adsId) => {
  return Api.get(
    "MRFUserManagements/GetAllScrapAddressById?adsId=" + adsId,
    {}
  );
};

export const apiRegistrationUpdateScrapAddress = (params) => {
  return Api.post("MRFUserManagements/UpdateScrapAddress", params);
};

export const apiRegistrationGetAllScrapAddressRegistrationsActive = () => {
  return Api.get("MRFUserManagements/GetAllScrapAddressActive", {});
};

export const apiRegistrationDeleteScrapAddressRegistrations = (adresssId) => {
  return Api.post(
    "MRFUserManagements/DeleteScrapAddress?adresssId=" + adresssId,
    {}
  );
};

export const apiMRFUserManagementsGetAllCollectionTypeMrfActive = () => {
  return Api.get("MRFUserManagements/GetAllCollectionPoint", {});
};

export const getSeqUpload = (params) => {
  return Api.post("/projectSequence/excelUpload", params);
};
//UserCreation
export const getAllMRFUserRoles = () => {
  return Api.get("/MRFUserManagements/GetAllMRFUserRoles");
};

export const apiMRFUserManagementsGetAllComponent = () => {
  return Api.get("MRFUserManagements/GetAllComponent", {});
};

export const apiAddMRFCollctionDetail = (params) => {
  return Api.post("MRFUserManagements/AddMRFCollectionDetail", params);
};

export const apiGetAllMRFCollectionDetails = () => {
  return Api.get("MRFUserManagements/GetAllMRFCollectionDetails");
};

export const apiRegistrationGetAllMRFUserById = (mrfUserRegistrationId) => {
  return Api.get(
    "MRFUserManagements/GetAllMRFUserRegistrationById?userRegistrationId=" +
      mrfUserRegistrationId,
    {}
  );
};

export const apiMRFUserManagementsAddUserRegistration = (params) => {
  return Api.post("MRFUserManagements/AddMRFUserRegistration", params);
};

export const apiMRFUserManagementsDeleteUserRegistrations = (urnId) => {
  return Api.post(
    "MRFUserManagements/DeleteMRFUserRegistration?urnId=" + urnId,
    {}
  );
};

export const apiMRFUserManagementsUpdateUserRegistration = (params) => {
  return Api.post("MRFUserManagements/UpdateMRFUserRegistration", params);
};

export const apiRegistrationGetAllMRFUserRegistrationsActive = () => {
  return Api.get(
    "MRFUserManagements/GetAllMRFUserRegistrationActive", //Active
    {}
  );
};

//news creation
export const apiMRFUserManagementsAddNewsRegistration = (params) => {
  return Api.post("MRFUserManagements/CreateNews", params);
};

export const apiMRFUserManagementsUpdateNewsRegistration = (params) => {
  return Api.post("MRFUserManagements/UpdateNews", params);
};

export const apiMRFUserManagementsDeleteNewsRegistrations = (mrfId) => {
  return Api.post("MRFUserManagements/DeleteNews?mrfId=" + mrfId, {});
};

export const apiRegistrationGetAllNEWSById = (newsId) => {
  return Api.get("MRFUserManagements/GetAllNewsById?typeOfMRFId=" + newsId, {});
};
export const apiRegistrationGetAllNewsActive = () => {
  return Api.get(
    "MRFUserManagements/GetAllNewsActive", //Active
    {}
  );
};

export const getAllMRFNEWSRoles = () => {
  return Api.get("/MRFUserManagements/GetAllNews");
};

export const apiRegistrationGetdocumentView = (params) => {
  return Api.get(
    "/MRFUserManagements/DownloadDocumentsByRef",
    {
      params,
    } //Active
  );
};
//instruction and Form
export const apiInstructandFormAddNewsRegistration = (params) => {
  return Api.post("MRFUserManagements/AddInformationAndForms", params);
};

export const apiInstructandFormUpdateNewsRegistration = (params) => {
  return Api.post("MRFUserManagements/UpdateInstructionAndForms", params);
};

export const apiInstructandFormDeleteNewsRegistrations = (mrfId) => {
  return Api.post(
    "MRFUserManagements/DeleteInstructionAndForms?mrfId=" + mrfId,
    {}
  );
};

export const apiRegistrationGetAllInstructandFormActive = () => {
  return Api.get(
    "MRFUserManagements/GetAllInstructionAndFormsActive", //Active
    {}
  );
};

export const getAllMRFInstructandFormRoles = () => {
  return Api.get("/MRFUserManagements/GetAllInstructionAndForms");
};

export const apiRegistrationGetAllInstructandFormById = (
  instructionAndFormsId
) => {
  return Api.get(
    "MRFUserManagements/GetAllInstructionAndFormsById?typeOfMRFId=" +
      instructionAndFormsId,
    {}
  );
};

//contact details
export const apiRegistrationGetAllContactById = (contactDetailsId) => {
  return Api.post(
    "MRFUserManagements/GetContactDetailsId?contactDetailId=" +
      contactDetailsId,
    {}
  );
};

export const apiMRFUserManagementsUpdatecontactdetails = (params) => {
  return Api.post("MRFUserManagements/UpdateContactDetails", params);
};

export const apiMRFUserManagementsGetMRFCollectionDetailByDate = (
  startDate,
  endDate,
  isMonth,
  isYear
) => {
  return Api.post(
    `MRFUserManagements/GetMRFCollectionDetailsByDateFilter?fromDate=${startDate}&toDate=${endDate}&isMonth=${isMonth}&isYear=${isYear}`
  );
};
