import React, { Component, useState } from "react";
import "./LCPC.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../../assets/images/iwms_logo_bg.jpg";
import Logo1 from "../../assets/images/Assist_Logo.svg";
import VietnamActiveIcon from "../../assets/icons/LoginIcons/vietnam_active.svg";
import VietnamInactiveIcon from "../../assets/icons/LoginIcons/vietnam_inactive.svg";
import UKActiveIcon from "../../assets/icons/LoginIcons/unitedkingdom_active.svg";
import UKInactiveIcon from "../../assets/icons/LoginIcons/unitedkingdom_inactive.svg";
import LCPCLogin from "../LCPCLogin/LCPCLoginPage";
import MenuBannerImage from "../../assets/images/menu_banner.png";
import { BsArrowDownRight, BsArrowRight, BsCheck } from "react-icons/bs";
import {
  BiRightArrow,
  BiCalendar,
  BiPlusCircle,
  BiRightArrowAlt,
} from "react-icons/bi";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
  FormSelect,
  ButtonGroup,
  Container,
  Button,
} from "reactstrap";

import {
  initLanguage,
  translateLanguage,
  checkedLanguagesKey,
  languageEnglish,
  languageVietnamese,
  defaultLanguage,
  allLanguages,
} from "../../services/CommonUtil";
import { RxCheck } from "react-icons/rx";
import ToggleSwitch from "../../component/ToggleSwitch";

class LCPCNavbar extends Component {
  constructor(props) {
    super(props);
    var locisCheckedLanguage = localStorage.getItem("isCheckedLanguage");
    if (locisCheckedLanguage === null || locisCheckedLanguage === undefined) {
      locisCheckedLanguage = defaultLanguage === languageEnglish;
      localStorage.setItem("isCheckedLanguage", locisCheckedLanguage);
    }
    var locpathname = "";
    try {
      locpathname = window.location.hash.split("?")[0];
    } catch (e) {}
    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
      isCheckedLanguage: locisCheckedLanguage === "true" ? true : false,
      pathname: locpathname,
      allLanguages: null,
      fromLogin: this.props.isLogin,
    };
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.updateWindowDimensions());
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  Fn_CheckedLanguage = (e) => {
    var checked = document.getElementById("rad_lang1").checked;
    this.setState({ isCheckedLanguage: checked });
    localStorage.setItem("isCheckedLanguage", checked);
    console.log(this.state.isCheckedLanguage);
    console.log(checked);
    setTimeout(function () {
      initLanguage(function () {
        window.location.reload();
      });
    }, 100);
  };
  Fn_WindowReload = () => {
    setTimeout(function () {
      window.location.reload();
    }, 0);
  };
  // Hide or show the menu.
  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
      screenWidth: null,
    });
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  };

  Fn_Logout = () => {
    localStorage.clear();

    //window.location.href = "/#/login";
    setTimeout(function () {
      window.location.href = "/";
    }, 1000);
  };

  Fn_LoginLogout = () => {
    if (
      localStorage.getItem("isLogin") === undefined ||
      localStorage.getItem("isLogin") === "false"
    ) {
      setTimeout(function () {
        window.location.href = "/#/login";
      }, 10);
    } else {
      localStorage.clear();
      localStorage.setItem("isLogin", "false");
      //window.location.href = "/#/login";
      setTimeout(function () {
        window.location.href = "/";
      }, 1000);
    }
  };

  handleToggleSwitchChage = (e) => {
    var checked = e.target.checked;
    // console.log("Checked->", checked);
    this.setState({ isCheckedLanguage: checked });
    localStorage.setItem("isCheckedLanguage", checked);
    // console.log(this.state.isCheckedLanguage);
    // console.log(checked);
    setTimeout(function () {
      initLanguage(function () {
        window.location.reload();
      });
    }, 100);
  };

  updateWindowDimensions() {
    this.setState({ screenWidth: window.innerWidth });
  }

  render() {
    const isLogin = localStorage.getItem("isLogin") === "true";
    return (
      <div className="container-fluid p-0">
        <Navbar className="col-md-12" bg="primary " text="white" expand="lg">
          <Nav className="col-12">
            <div class="col-12 topnav d-flex">
              <Col md={3} className="desktop_language">
                <Row>
                  <div className="menu-language-switch">
                    {allLanguages.map((language, index) => (
                      <>
                        {language.languageText +
                          (language.languageId === languageVietnamese
                            ? " | "
                            : "")}
                      </>
                    ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {allLanguages.map((language, index) => (
                        <>
                          {language.languageId === languageEnglish ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                // gap: "3px",
                              }}
                            >
                              {/* <Button
                                color="transparent !important"
                              >
                                <Input
                                  id="rad_lang1"
                                  type="radio"
                                  name="radio2"
                                  checked={this.state.isCheckedLanguage}
                                  onChange={this.Fn_CheckedLanguage}
                                />
                                {this.state.isCheckedLanguage && (
                                  <RxCheck className="radio_check" size="25" />
                                )}
                              </Button>  */}
                              <ToggleSwitch
                                label=" "
                                onChange={(e) =>
                                  this.handleToggleSwitchChage(e)
                                }
                                isCheckedLanguage={this.state.isCheckedLanguage}
                              />
                              {/* <h6 className="lang_swtich">EN</h6> */}

                              <img
                                width={30}
                                height={30}
                                src={UKActiveIcon}
                                alt=""
                                className="rounded-img mx-auto d-block"
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: "10px",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <img
                                width={30}
                                height={30}
                                src={VietnamActiveIcon}
                                alt=""
                                className="rounded mx-auto d-block"
                              />
                              {/* <h6 className="lang_swtich">VN</h6> */}
                              {/* <Button
                                color="transparent !important"
                              // className="btn-pill btn btn-warning"
                              >
                                <Input
                                  id="rad_lang2"
                                  type="radio"
                                  name="radio2"
                                  checked={!this.state.isCheckedLanguage}
                                  onChange={this.Fn_CheckedLanguage}
                                />
                                {!this.state.isCheckedLanguage && (
                                  <RxCheck className="radio_check" size="25" />
                                )}
                              </Button> */}
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </Row>
              </Col>

              <Col md={7} className="log_name">
                <div className="d-flex justify-content-center">
                  <img
                    width={60}
                    height={60}
                    src={Logo}
                    alt=""
                    className="rounded image-btn d-block"
                  />
                  <div className="d-flex justify-content-center ">
                    <h3 className="myHeading mt-3 text-white text-center">
                      {translateLanguage(
                        checkedLanguagesKey,
                        "clean_city_blue_ocean"
                      )}
                    </h3>
                  </div>

                  <img
                    width={60}
                    height={60}
                    src={Logo1}
                    alt=""
                    className="rounded image-btn d-block"
                  />
                </div>
              </Col>

              {/* <Col md={2} className="d-flex justify-content-center">
                <button type="button" class="btn btn-outline-dark">
                  Logout
                </button>
              </Col> */}

              <Col md={2} className="d-flex text-right">
                <Button
                  className="mb-2 me-2 btn-border fs-4 top_fn_logout"
                  onClick={this.Fn_LoginLogout}
                >
                  {localStorage.getItem("isLogin") === undefined ||
                  localStorage.getItem("isLogin") === "false"
                    ? translateLanguage(checkedLanguagesKey, "login")
                    : translateLanguage(checkedLanguagesKey, "logout")}
                </Button>
              </Col>
            </div>
          </Nav>
        </Navbar>
        {this.state.fromLogin ? (
          <></>
        ) : (
          <Navbar
            className="text-center"
            bg="secondary"
            text="primary"
            // var
            expand="lg"
          >
            <Col md={3} className="mobile_language">
              <Row>
                <div className="menu-language-switch">
                  {allLanguages.map((language, index) => (
                    <>
                      {language.languageText +
                        (language.languageId === languageVietnamese
                          ? " | "
                          : "")}
                    </>
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {allLanguages.map((language, index) => (
                        <>
                          {language.languageId === languageEnglish ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                // gap: "3px",
                              }}
                            >
                              {/* <Button
                                color="transparent !important"
                              >
                                <Input
                                  id="rad_lang1"
                                  type="radio"
                                  name="radio2"
                                  checked={this.state.isCheckedLanguage}
                                  onChange={this.Fn_CheckedLanguage}
                                />
                                {this.state.isCheckedLanguage && (
                                  <RxCheck className="radio_check" size="25" />
                                )}
                              </Button>  */}
                              <ToggleSwitch
                                label=" "
                                onChange={(e) =>
                                  this.handleToggleSwitchChage(e)
                                }
                                isCheckedLanguage={this.state.isCheckedLanguage}
                              />
                              {/* <h6 className="lang_swtich text-blue">EN</h6> */}

                              <img
                                width={30}
                                height={30}
                                src={UKActiveIcon}
                                alt=""
                                className="rounded-img mx-auto d-block"
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: "10px",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <img
                                width={30}
                                height={30}
                                src={VietnamActiveIcon}
                                alt=""
                                className="rounded mx-auto d-block"
                              />
                              {/* <h6 className="lang_swtich text-red">VN</h6> */}
                              {/* <Button
                                color="transparent !important"
                              // className="btn-pill btn btn-warning"
                              >
                                <Input
                                  id="rad_lang2"
                                  type="radio"
                                  name="radio2"
                                  checked={!this.state.isCheckedLanguage}
                                  onChange={this.Fn_CheckedLanguage}
                                />
                                {!this.state.isCheckedLanguage && (
                                  <RxCheck className="radio_check" size="25" />
                                )}
                              </Button> */}
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </Row>
            </Col>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="col-12">
                <Nav.Link
                 className={isLogin ? "col-4" :"col-4"}
                  href="/#/LCPCDashboard"
                  onClick={this.Fn_WindowReload}
                >
                  {translateLanguage(checkedLanguagesKey, "dashboard")}
                </Nav.Link>
                <Nav.Link
                  className={isLogin ? "col-4" :"col-4"}
                  href="/#/LCPCReports"
                  onClick={this.Fn_WindowReload}
                >
                  {translateLanguage(checkedLanguagesKey, "reports")}
                </Nav.Link>
                <NavDropdown
                  title={translateLanguage(checkedLanguagesKey, "configure")}
                  // title="Configure Masters"
                  id="basic-nav-dropdown"
                  className={isLogin ? "col-4" :"col-4"}
                >
                  <NavDropdown.Item href="/#/MRFStationList">
                    <div className="row">
                      <div className="col-3">
                        {this.state.pathname === "#/MRFStationList" ||
                        this.state.pathname === "#/MRFStationList" ? (
                          <BsCheck size={25} color="white" />
                        ) : (
                          ""
                        )}
                        {translateLanguage(checkedLanguagesKey, "mrf")}
                      </div>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/#/QRCodeList">
                    <div className="row">
                      <div className="col-3">
                        {this.state.pathname === "#/QRCodeList" ||
                        this.state.pathname === "#/QRCodeList" ? (
                          <BsCheck size={25} color="white" />
                        ) : (
                          ""
                        )}
                        {translateLanguage(checkedLanguagesKey, "qrcode")}
                      </div>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/#/NewsmasterList">
                    <div className="row">
                      <div className="col-3">
                        {this.state.pathname === "#/NewsmasterList" ||
                        this.state.pathname === "#/NewsmasterList" ? (
                          <BsCheck size={25} color="white" />
                        ) : (
                          ""
                        )}
                        {translateLanguage(checkedLanguagesKey, "news")}
                      </div>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/#/InstructFormList">
                    <div className="row">
                      <div className="col-3">
                        {this.state.pathname === "#/InstructFormList" ||
                        this.state.pathname === "#/InstructFormList" ? (
                          <BsCheck size={25} color="white" />
                        ) : (
                          ""
                        )}
                        {translateLanguage(checkedLanguagesKey, "instruct")}
                      </div>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/#/UserCreationList">
                    <div className="row">
                      <div className="col-3">
                        {this.state.pathname === "#/UserCreationList" ||
                        this.state.pathname === "#/UserCreationList" ? (
                          <BsCheck size={25} color="white" />
                        ) : (
                          ""
                        )}
                        {translateLanguage(checkedLanguagesKey, "manage")}
                      </div>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/#/Contactdetails">
                    <div className="row">
                      <div className="col-3">
                        {this.state.pathname === "#/Contactdetails" ||
                        this.state.pathname === "#/Contactdetails" ? (
                          <BsCheck size={25} color="white" />
                        ) : (
                          ""
                        )}
                        {translateLanguage(checkedLanguagesKey, "contact")}
                      </div>
                    </div>
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                // className="col-3 top_fn_logout"
                  className={isLogin ? "col-4 top_fn_logout" : "col-4 top_fn_logout"}
                  href="#/MRFLogin"
                  onClick={isLogin ? this.Fn_Logout : this.Fn_LoginLogout}
                >
                  {isLogin
                    ? translateLanguage(checkedLanguagesKey, "logout")
                    : translateLanguage(checkedLanguagesKey, "login")}
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        )}
      </div>
    );
  }
}

export default LCPCNavbar;
