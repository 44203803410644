import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Navber from "../Navber/Navber";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
  FormSelect,
  ButtonGroup,
  Button,
  Container,
} from "reactstrap";

import {
  apiRegistrationGetAllAddressRegistrationById,
  apiRegistrationAddAddressRegistration,
  apiRegistrationGetAllCountryRegistrationsActive,
  apiConfigurationsGetAllProvinceByCountryId,
  apiConfigurationsGetAllCityByProvinceId,
  apiRegistrationGetAllPostalCodeById,
  apiRegistrationUpdateAddressRegistration,
  apiMRFUserManagementsGetAllCollectionTypeMrfActive,
} from "../../services/MicroService";

import {
  initLanguage,
  translateLanguage,
  checkedLanguagesKey,
  languageEnglish,
  languageVietnamese,
  defaultLanguage,
  printDivContent,
} from "../../services/CommonUtil";
import "./QRCodes.css";
import LCPCNavbar from "../LCPCNavBar/LCPCNavbar";
import QRCodeList from "./QRCodeList";
const ts = (key) => {
  return translateLanguage(checkedLanguagesKey, key);
};

const QRCode = () => {
  const [mrfType, setMrfType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [checkedAddressTypeResidential, setCheckedAddressTypeResidential] =
    useState(true);
  const [allCountryList, setAllCountryList] = useState([]);
  const [allProvinceList, setAllProvinceList] = useState([]);
  const [allCitiesList, setAllCitiesList] = useState([]);
  const [allPostalCodeList, setAllPostalCodeList] = useState([]);
  const [activeAddressQRText, setActiveAddressQRText] = useState("NO_DATA");
  const [defaultCountryId, setDefaultCountryId] = useState(1);

  const [selectedCountry, setSelectedCountry] = useState(defaultCountryId);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedPostalCode, setSelectedPostalCode] = useState(null);
  //   const [selectedCollection, setSelectedCollection] = useState(defaultCountryId);

  const [isAddressEntered, setIsAddressEntered] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [checkedAddressID, setCheckedAddressID] = useState(
    searchParams.get("checkedAddressID")
  );
  const [mode, setMode] = useState(searchParams.get("mode"));

  const [screenTranslateLanguage, setScreenTranslateLanguage] = useState({});
  const [allRoleMRFList, setAllRoleMRFList] = useState([]);
  const [pasted, setPasted] = useState("");

  const sAlert = (msg) => {
    setShowAlert(true);
    setAlertMessage(ts(msg));
  };

  const Fn_onCancelAlert = (e) => {
    return true;
  };
  const handleMrfTypeChange = (e) => {
    setMrfType(e.target.value);
  };
  useEffect(() => {
    //var checkedAddressID = searchParams.get("checkedAddressID");
    if (
      checkedAddressID !== null &&
      checkedAddressID !== "" &&
      checkedAddressID !== undefined
    ) {
      setIsEditMode(true);
      Fn_EditGetAllAddressRegistrationById(checkedAddressID);
    } else {
      setIsEditMode(false);
    }

    if (mode === "view") {
      setIsAddressEntered(true);
      Fn_GetAllAddressRegistrationById(checkedAddressID);
    }
  }, []);

  useEffect(() => {
    initLanguage(function () {
      Fn_screenTranslateLanguageUpdate();
    });
  }, []);

  useEffect(() => {
    console.log("isAddressEntered");
    console.log(isAddressEntered);
  }, [isAddressEntered]);
  useEffect(() => {
    apiMRFUserManagementsGetAllCollectionTypeMrfActive()
      .then((response) => {
        console.log(
          "apiMRFUserManagementsGetAllCollectionTypeMrfActive response"
        );
        console.log(response);
        if (response.status == 200) {
          var dataObj = response.data;
          var contentArr = dataObj.content;
          setAllRoleMRFList(contentArr);
          console.log(allRoleMRFList);
        }
      })
      .catch((err) => {
        // setAlertmessage(err.response.data.message)
      });
  }, []);
  useEffect(() => {
    apiRegistrationGetAllCountryRegistrationsActive()
      .then((response) => {
        console.log("apiRegistrationGetAllCountryRegistrationsActive response");
        console.log(response);
        if (response.status == 200) {
          var dataObj = response.data;
          var contentArr = dataObj.content;
          setAllCountryList(contentArr);
          setSelectedCountry(contentArr[0].countryId);
        }
      })
      .catch((err) => {
        // setAlertmessage(err.response.data.message)
      });
  }, []);

  useEffect(() => {
    console.log("selectedCountry");
    console.log(selectedCountry);

    if (selectedCountry != null) {
      apiConfigurationsGetAllProvinceByCountryId(selectedCountry)
        .then((response) => {
          console.log("apiConfigurationsGetAllProvinceByCountryId response");
          console.log(response);
          if (response.status == 200) {
            var dataObj = response.data;
            var contentArr = dataObj.content;
            setAllProvinceList(contentArr);
            if (contentArr.length > 0) {
              setSelectedProvince(contentArr[0]["provinceId"]);
            }
          }
        })
        .catch((err) => {
          // setAlertmessage(err.response.data.message)
        });
    }
  }, [selectedCountry]);

  useEffect(() => {
    console.log("selectedProvince");
    console.log(selectedProvince);

    if (selectedProvince != null) {
      apiConfigurationsGetAllCityByProvinceId(selectedProvince)
        .then((response) => {
          console.log("apiConfigurationsGetAllCityByProvinceId response");
          console.log(response);
          if (response.status == 200) {
            var dataObj = response.data;
            var contentArr = dataObj.content;
            setAllCitiesList(contentArr);

            if (contentArr.length > 0) {
              setSelectedCity(contentArr[0]["cityId"]);
            } else {
              setSelectedCity(null);
            }
          }
        })
        .catch((err) => {
          // setAlertmessage(err.response.data.message)
        });
    } else {
      setSelectedCity(null);
    }
  }, [selectedProvince]);

  useEffect(() => {
    console.log("selectedCity");
    console.log(selectedCity);
    if (selectedCity != null) {
      apiRegistrationGetAllPostalCodeById(selectedCity)
        .then((response) => {
          console.log("apiRegistrationGetAllPostalCodeById response");
          console.log(response);
          if (response.status == 200) {
            var dataObj = response.data;
            var contentArr = dataObj.content;
            setAllPostalCodeList(contentArr);
            if (contentArr.length > 0) {
              setSelectedPostalCode(contentArr[0]["postalCodeId"]);
            } else {
              setSelectedPostalCode(null);
            }
          }
        })
        .catch((err) => {
          // setAlertmessage(err.response.data.message)
        });
    } else {
      setAllPostalCodeList([]);
      setSelectedPostalCode(null);
    }
  }, [selectedCity]);

  const navigate = useNavigate();
  //class Address extends Component {

  const Fn_screenTranslateLanguageUpdate = () => {
    setScreenTranslateLanguage({});
  };

  const Fn_getElementById = (id) => {
    console.log(id);
    return document.getElementById(id).value;
  };

  const Fn_setElementById = (id, value) => {
    document.getElementById(id).innerText = value;
  };

  const Fn_setElementByIdValue = (id, value) => {
    document.getElementById(id).value = value;
  };

  const Fn_validateEmpty = (value) => {
    return value === undefined || value == null || value === "";
  };

  const Fn_EditGetAllAddressRegistrationById = async (adsId) => {
    try {
      const response = await apiRegistrationGetAllAddressRegistrationById(
        adsId
      );

      // console.log("apiRegistrationGetAllAddressRegistrationById response");
      // console.log(response);
      if (response.status === 200) {
        var dataObj = response.data;

        var content = dataObj.content[0];
        console.log(content);
        console.log(content.addressBuildingName);

        Fn_setElementByIdValue("txt_house_name", content.addressBuildingName);
        await new Promise((resolve) => setTimeout(resolve, 100));
        Fn_setElementByIdValue(
          "txt_collection_point",
          content.addressCollectionPoint
        );
        Fn_setElementByIdValue("txt_UniqueID", content.addressUniqueId);
        Fn_setElementByIdValue(
          "txt_ContactPerson",
          content.addressContactPersonName
        );
        Fn_setElementByIdValue(
          "txt_ContactNumber",
          content.addressContactNumber
        );
        Fn_setElementByIdValue("txt_house_no", content.addressBuildingNumber);
        Fn_setElementByIdValue("txt_address_l1", content.addressLine1);
        Fn_setElementByIdValue("txt_address_l2", content.addressLine2);
        Fn_setElementByIdValue("txt_lat", content.addressLat);
        Fn_setElementByIdValue("txt_lang", content.addressLong);
      }
    } catch (err) {
      // Handle error
    }
  };

  const Fn_GetAllAddressRegistrationById = (adsId) => {
    apiRegistrationGetAllAddressRegistrationById(adsId)
      .then((response) => {
        // console.log("apiRegistrationGetAllAddressRegistrationById response");
        // console.log(response);
        if (response.status === 200) {
          var dataObj = response.data;

          var content = dataObj.content[0];
          console.log(content);
          console.log(content.addressBuildingName);
          Fn_setElementByIdValue("lbl_uniqueid", content.addressUniqueId);
          Fn_setElementByIdValue(
            "lbl_contactPersonName",
            content.addressContactPersonName
          );
          Fn_setElementByIdValue("lbl_contactNumber", content.addressContactNumber);
          Fn_setElementByIdValue("lbl_house_name", content.addressBuildingName);
          Fn_setElementByIdValue(
            "lbl_addresstype",
            content.addressType === 1
              ? translateLanguage(checkedLanguagesKey, "residential")
              : translateLanguage(checkedLanguagesKey, "commercial")
          );
          Fn_setElementByIdValue("lbl_house_no", content.addressBuildingNumber);
          Fn_setElementByIdValue("lbl_address_l1", content.addressLine1);
          Fn_setElementByIdValue("lbl_address_l2", content.addressLine2);
          Fn_setElementByIdValue("lbl_lat", content.addressLat);
          Fn_setElementByIdValue("lbl_lang", content.addressLong);

          if (mode === "view") {
            var qr_code_data_display = content.addressFullNumber;
            setActiveAddressQRText(qr_code_data_display);
            document.getElementById("qr_code_data_display").innerHTML =
              qr_code_data_display;
            Fn_CreateAddressQRCode(qr_code_data_display);
          }
        }
      })
      .catch((err) => {
        // setAlertmessage(err.response.data.message)
      });
  };

  const Fn_ResetAddress = () => {
    Fn_getElementById("txt_UniqueID", "");
    Fn_getElementById("txt_ContactPerson");
    Fn_getElementById("txt_ContactNumber");
    Fn_setElementByIdValue("txt_house_name", "");
    setMrfType(false);
    Fn_setElementByIdValue("txt_house_no", "");
    Fn_setElementByIdValue("txt_address_l1", "");
    Fn_setElementByIdValue("txt_address_l2", "");
    Fn_setElementByIdValue("txt_lat", "");
    Fn_setElementByIdValue("txt_lang", "");
  };

  const Fn_CancelAddress = () => {
    Fn_ResetAddress();
    Fn_GoToAddressList();
  };

  const Fn_EditAddress = () => { };
  const Fn_PrintAddress = () => {
    printDivContent(activeAddressQRText);
  };

  const Fn_BackAddress = () => {
    Fn_GoToAddressList();
  };
  /* QR Code */

  const Fn_CreateAddressQRCode = (qrCodeValue) => {
    let finalURL =
      "https://chart.googleapis.com/chart?cht=qr&chl=" +
      qrCodeValue +
      "&chs=280x280&chld=L|0";

    // Replace the src of the image with
    // the QR code image
    //$("#qr_code_image_display").attr("src", finalURL);
    document
      .getElementById("qr_code_image_display")
      .setAttribute("src", finalURL);
  };

  const Fn_CheckBoxOnChnage = (e) => {
    if (e.target.value == "1" && e.target.checked) {
      setCheckedAddressTypeResidential(true);
    } else if (e.target.value == "2" && e.target.checked) {
      setCheckedAddressTypeResidential(false);
    }
  };

  const Fn_CreateAddress = () => {
    var txt_UniqueID = Fn_getElementById("txt_UniqueID");
    var txt_ContactPerson = Fn_getElementById("txt_ContactPerson");
    var txt_ContactNumber = Fn_getElementById("txt_ContactNumber");
    var txt_house_no = Fn_getElementById("txt_house_no");
    var txt_house_name = Fn_getElementById("txt_house_name");

    var txt_address_l1 = Fn_getElementById("txt_address_l1");
    var txt_address_l2 = Fn_getElementById("txt_address_l2");
    var txt_lat = Fn_getElementById("txt_lat");
    var txt_lang = Fn_getElementById("txt_lang");
    var txt_collection_point = Fn_getElementById("txt_collection_point");

    if (Fn_validateEmpty(txt_UniqueID)) {
      sAlert("enter_the_Unique_Id");

      return false;
    } else if (Fn_validateEmpty(txt_ContactPerson)) {
      sAlert("enter_the_contact_name");
      return false;
    } else if (Fn_validateEmpty(txt_ContactNumber)) {
      sAlert("enter_the_contact_number");
      return false;
    } else if (Fn_validateEmpty(txt_collection_point)) {
      sAlert("enter_the_collection_point");
      return false;
    } else if (Fn_validateEmpty(txt_house_no)) {
      sAlert("enter_the_txt_building");
      return false;
    } else if (Fn_validateEmpty(txt_address_l1)) {
      sAlert("kindly_enter_the_address_line1");
      return false;
    } else if (Fn_validateEmpty(txt_address_l2)) {
      sAlert("kindly_enter_the_address_line2");
      return false;
    }
    if (isEditMode) {
      var payload = {
        //
        addressUniqueId: txt_UniqueID,
        addressContactPersonName: txt_ContactPerson,
        addressContactNumber: txt_ContactNumber,
        addressBuildingNumber: txt_house_no.trim(),
        addressId: checkedAddressID,
        addressBuildingName: txt_house_name,
        addressLat: txt_lat,
        addressLong: txt_lang,
        addressCollectionPoint: txt_collection_point,
        addressType: txt_collection_point,
        addressLine1: txt_address_l1,
        addressLine2: txt_address_l2,
      };

      apiRegistrationUpdateAddressRegistration(payload)
        .then((response) => {
          console.log("apiRegistrationUpdateAddressRegistration response");
          console.log(response);
          if (response.status === 200) {
            var dataObj = response.data;
            // var adsId = dataObj.id;
            var type = dataObj.type;
            var code = dataObj.code;
            var fullnumber = dataObj.fullnumber; //checkedAddressID

            setActiveAddressQRText(fullnumber);
            if (code === 200) {
              if (type === "Success") {
                sAlert("address_updated_qr_code_generated_successfully");
                setTimeout(function () {
                  navigate("/QRCodeList");
                }, 1500);
              } else if (type === "AddressExistsFailed") {
                sAlert("address_already_exists");
              } else {
                sAlert("unknowm_failed");
              }
            }
          }
        })
        .catch((err) => {
          // setAlertmessage(err.response.data.message)
        });
    } else {
      var addPayload = {
        addressUniqueId: txt_UniqueID,
        addressContactPersonName: txt_ContactPerson,
        addressContactNumber: txt_ContactNumber,
        addressLat: txt_lat,
        addressLong: txt_lang,
        addressBuildingName: txt_house_name,
        addressBuildingNumber: txt_house_no.trim(),
        addressLine1: txt_address_l1,
        addressLine2: txt_address_l2,
        addressCollectionPoint: txt_collection_point,
        addressType: txt_collection_point,
      };

      apiRegistrationAddAddressRegistration(addPayload)
        .then((response) => {
          console.log("apiRegistrationAddAddressRegistration response");
          console.log(response);
          if (response.status === 200) {
            var dataObj = response.data;
            var adsId = dataObj.id;
            var type = dataObj.type;
            var code = dataObj.code;
            var fullnumber = dataObj.fullnumber;
            setActiveAddressQRText(fullnumber);
            if (code === 200) {
              if (type === "Success") {
                // setIsAddressEntered(true);
                var qr_code_data_display = fullnumber;
                // document.getElementById("qr_code_data_display").innerHTML =
                //   qr_code_data_display;
                // Fn_CreateAddressQRCode(qr_code_data_display);
                Fn_GetAllAddressRegistrationById(adsId);
                sAlert("address_created_QR_code_generate_successfully");
                //navigate("/address?checkedAddressID=" + adsId);
                setTimeout(function () {
                  navigate("/QRCodeList");
                }, 2000);
              } else if (type === "AddressExistsFailed") {
                sAlert("address_already_exists");
              } else {
                sAlert("unknowm_failed");
              }
            }
          }
        })
        .catch((err) => {
          // setAlertmessage(err.response.data.message)
          console.log("Error->", err);
        });
    }
  };

  const Fn_GoToAddressList = () => {
    navigate("/QRCodeList");
  };

  const Fn_onChangeCountry = () => {
    var sel_country = Fn_getElementById("sel_country");
    setSelectedCountry(sel_country);
  };

  const Fn_onChangeProvince = () => {
    var sel_province = Fn_getElementById("sel_province");
    setSelectedProvince(sel_province);
  };

  const Fn_onChangeCity = () => {
    var sel_city = Fn_getElementById("sel_city");
    setSelectedCity(sel_city);
  };

  const Fn_onChangePostalCode = () => {
    var sel_postalcode = Fn_getElementById("sel_postalcode");
    setSelectedPostalCode(sel_postalcode);
  };
  const handlePaste = (event) => {
    var text_user_phone_no_value =
      document.getElementById("txt_ContactNumber").value;
    const pastedText = event.clipboardData.getData("text");
    var totallength = text_user_phone_no_value.length + pastedText.length;
    if (totallength <= 10 && /^\s*\d+\s*$/.test(pastedText)) {
      setPasted(pastedText.trim());
    } else {
      event.preventDefault();
    }
  };
  const handleKeyPress = (event) => {
    const inputValue = event.target.value;
    if (
      inputValue.length >= 10 ||
      (event.key.length === 1 && /\D/.test(event.key))
    ) {
      event.preventDefault();
    }
  };

  //render() {
  return (
    <div>
      {/* <Navber /> */}
      <LCPCNavbar />
      {mode === "view" ? (
        <></>
      ) : (

        <Card className="box-shad-none">
          <CardBody>
            <div className="home d-flex justify-content-center">
              <Col md={{ size: 8 }}>

                <Form>
                  <Row>
                    {" "}
                    <h4 className="d-flex justify-content-center bold mb-4">
                      {/* <text>QR code generation for collection point</text> */}
                      {translateLanguage(
                        checkedLanguagesKey,
                        mode === "edit"
                          ? "update_collection_details_title"
                          : "QR_Details_entered"
                      )}
                    </h4>
                  </Row>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={4} className="required">
                      {translateLanguage(checkedLanguagesKey, "unique_ID")}
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="text"
                        name="text"
                        id="txt_UniqueID"
                        placeholder=""
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={4} className="required">
                      {translateLanguage(
                        checkedLanguagesKey,
                        "contact_person_name"
                      )}
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="text"
                        name="text"
                        id="txt_ContactPerson"
                        placeholder=""
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleText" sm={4} className="required">
                      {translateLanguage(
                        checkedLanguagesKey,
                        "UserCreation_Number"
                      )}
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="text"
                        name="text"
                        id="txt_ContactNumber"
                        placeholder=""
                        // required
                        onPaste={handlePaste}
                        onKeyPress={handleKeyPress}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={4}>
                      {translateLanguage(
                        checkedLanguagesKey,
                        "house_building_name"
                      )}
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="text"
                        name="text"
                        id="txt_house_name"
                        placeholder=""
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup tag="fieldset" row>
                    <Label for="exampleEmail" sm={4} className="required">
                      {translateLanguage(
                        checkedLanguagesKey,
                        "type_of_collection"
                      )}
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="select"
                        name="txt_collection_point" // Use a more descriptive name
                        id="txt_collection_point"
                        required // Use the 'required' attribute directly
                      >
                        <option value="">
                          {translateLanguage(
                            checkedLanguagesKey,
                            "dropdown_select_title"
                          )}
                        </option>
                        {allRoleMRFList.map((role, index) => (
                          <>
                            <option value={role.collectionPointId}>
                              {role.collectionPointName}
                            </option>
                          </>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleSelect" sm={4} className="required">
                      {translateLanguage(checkedLanguagesKey, "building_no")}
                    </Label>
                    <Col sm={8}>
                      <Input type="text" name="text" id="txt_house_no" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label
                      for="exampleSelectMulti"
                      sm={4}
                      className="required"
                    >
                      {translateLanguage(
                        checkedLanguagesKey,
                        "address_line1"
                      )}
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="text"
                        name="text"
                        id="txt_address_l1"
                        placeholder=""
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleText" sm={4} className="required">
                      {translateLanguage(
                        checkedLanguagesKey,
                        "address_line2"
                      )}
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="text"
                        name="text"
                        id="txt_address_l2"
                        placeholder=""
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="exampleFile" sm={4}>
                      {translateLanguage(
                        checkedLanguagesKey,
                        "latitude_title"
                      )}
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="text"
                        name="lat"
                        id="txt_lat"
                        placeholder=""
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleFile" sm={4}>
                      {translateLanguage(
                        checkedLanguagesKey,
                        "longitude_title"
                      )}
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="text"
                        name="lang"
                        id="txt_lang"
                        placeholder=""
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col className="container">
                      <div className="button-container">
                        <Button
                          className="mb-2 me-2 side"
                          active
                          color="primary"
                          onClick={Fn_CreateAddress}
                        >
                          {translateLanguage(checkedLanguagesKey, "submit")}
                        </Button>
                        <Button
                          className="mb-2 me-2 btn-danger"
                          active
                          color="cancel"
                          onClick={Fn_CancelAddress}
                        >
                          {translateLanguage(checkedLanguagesKey, "cancel")}
                        </Button>
                      </div>
                    </Col>
                  </FormGroup>
                </Form>


              </Col>

            </div>
          </CardBody>
        </Card>
      )}

      {isAddressEntered ? (
        <div class="fixed-header fixed-sidebar fixed-footer">
          <div md="10" className="mx-auto app-login-box col-md-10">

            <Col md={{ size: 10, offset: 1 }}>
              <Card className="home d-flex justify-center p-2">
                <CardBody className="d-flex justify-content-center p-2">
                  <Row className="form-row">
                    <Col md={12}>
                      <Form>
                        <h4 className="d-flex justify-content-center bold mb-4">
                          {translateLanguage(
                            checkedLanguagesKey,
                            "view_collection_details_title"
                          )}
                        </h4>


                        <FormGroup row className="mb-low">
                          <Label for="" sm={5}>
                            {translateLanguage(
                              checkedLanguagesKey,
                              "Unique_ID"
                            )}
                          </Label>

                          <Input
                            type="text"
                            name="text"
                            id="lbl_uniqueid"
                            placeholder=""
                            readOnly
                          />
                        </FormGroup>
                        <FormGroup row className="mb-low">
                          <Label for="" sm={5}>
                            {translateLanguage(
                              checkedLanguagesKey,
                              "UserCreation_Name"
                            )}
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="lbl_contactPersonName"
                            placeholder=""
                            readOnly
                          />
                        </FormGroup>
                        <FormGroup row className="mb-low">
                          <Label for="" sm={5}>
                            {translateLanguage(
                              checkedLanguagesKey,
                              "UserCreation_Number"
                            )}
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="lbl_contactNumber"
                            placeholder=""
                            readOnly
                          />
                        </FormGroup>
                        <FormGroup row className="mb-low">
                          <Label for="" sm={5}>
                            {translateLanguage(
                              checkedLanguagesKey,
                              "house_building_name"
                            )}
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="lbl_house_name"
                            placeholder=""
                            readOnly
                          />
                        </FormGroup>
                        <FormGroup tag="fieldset" row className="mb-low">
                          <Label for="" sm={5}>
                            {translateLanguage(
                              checkedLanguagesKey,
                              "type_of_address"
                            )}
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="lbl_addresstype"
                            placeholder=""
                            readOnly
                          />
                        </FormGroup>
                        <FormGroup row className="mb-low">
                          <Label
                            for="exampleSelect"
                            sm={5}
                            className="required"
                          >
                            {translateLanguage(
                              checkedLanguagesKey,
                              "house_building_no"
                            )}
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="lbl_house_no"
                            placeholder=""
                            readOnly
                          />
                        </FormGroup>
                        <FormGroup row className="mb-low">
                          <Label
                            for="exampleSelectMulti"
                            sm={5}
                            className="required"
                          >
                            {translateLanguage(
                              checkedLanguagesKey,
                              "address_line1"
                            )}
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="lbl_address_l1"
                            placeholder=""
                            readOnly
                          />
                        </FormGroup>
                        <FormGroup row className="mb-low">
                          <Label
                            for="exampleText"
                            sm={5}
                            className="required"
                          >
                            {translateLanguage(
                              checkedLanguagesKey,
                              "address_line2"
                            )}
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="lbl_address_l2"
                            placeholder=""
                            readOnly
                          />
                        </FormGroup>
                        <FormGroup row className="mb-low">
                          <Label for="exampleFile" sm={5}>
                            {translateLanguage(
                              checkedLanguagesKey,
                              "latitude_title"
                            )}
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="lbl_lat"
                            placeholder=""
                            readOnly
                          />
                        </FormGroup>
                        <FormGroup row className="mb-low">
                          <Label for="exampleFile" sm={5}>
                            {translateLanguage(
                              checkedLanguagesKey,
                              "longitude_title"
                            )}
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="lbl_lang"
                            placeholder=""
                            readOnly
                          />
                        </FormGroup>

                        <div id="div_qr_code_print_display">
                          <Row className="mt-0">
                            <img
                              id="qr_code_image_display"
                              src=""
                              class="qr-code ml-0"
                            ></img>
                          </Row>
                          <Row className="d-flex justify-content-center">
                            <FormGroup row className="mb-low mt-3 text-center">
                              <Label
                                for="exampleFile"
                                sm={6}
                                className="text-center"
                              >
                                {translateLanguage(
                                  checkedLanguagesKey,
                                  "qr_code_no"
                                ) + " : "}
                              </Label>
                              <Label
                                for="exampleEmail"
                                id="qr_code_data_display"
                                sm={6}
                                className="text-left"
                              >
                                -
                              </Label>
                            </FormGroup>
                          </Row>
                        </div>
                        <FormGroup row>
                          <Col className="container">
                            <div className="d-flex justify-content-center">
                              <Button
                                className="mb-2 me-2 "
                                active
                                color="primary"
                                onClick={Fn_PrintAddress}
                              >
                                {translateLanguage(
                                  checkedLanguagesKey,
                                  "print"
                                )}
                              </Button>
                              {/* {mode == "view" ? (
                                <Button
                                  className="mb-2 me-2 btn-danger"
                                  active
                                  color="cancel"
                                  onClick={Fn_BackAddress}
                                >
                                  {translateLanguage(
                                    checkedLanguagesKey,
                                    "back"
                                  )}
                                </Button>
                              ) : (
                                ""
                              )} */}
                            </div>
                          </Col>
                        </FormGroup>
                      </Form>
                    </Col>
                    <Col md="4" className="mt-2" style={{ width: "100%" }}>
                      <Button color="cancel" className="btn btn-primary1" onClick={Fn_GoToAddressList} style={{ float: "right" }}>
                        {translateLanguage(
                          checkedLanguagesKey,
                          "back_to_list_title"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

          </div>
          <div class="Toastify"></div>
        </div>
      ) : (
        ""
      )}
      {alertMessage !== "" ? (
        <SweetAlert
          title={translateLanguage(checkedLanguagesKey, "alert")}
          show={showAlert}
          onConfirm={() => {
            setShowAlert(false);
          }}
          onCancel={() => {
            return true;
          }}
        >
          {alertMessage}
        </SweetAlert>
      ) : (
        ""
      )}
    </div>
  );
  //}
};

export default QRCode;
