import React from "react";
import "../css/ToggleSwitch.css";

// const ToggleSwitch = ({ label }) => {
// return (
// <div className="container">
// 	{label}{" "}
// <div className="toggle-switch">
// <input type="checkbox" className="checkbox"
// 			name={label} id={label} />
// <label className="label" htmlFor={label}>
// <span className="inner" />
// <span className="switch" />
// </label>
// </div>
// </div>
// );
// };

const ToggleSwitch = (props) => {
  // console.log("Checked ->", props.isCheckedLanguage);
  //   console.log("Props ->", props);
  return (
    <div className="container">
      {props.label}{" "}
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
          checked={props.isCheckedLanguage}
          name={props.label}
          id={props.label}
          onChange={props.onChange}
        />
        <label className="label" htmlFor={props.label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
