import React, { Component, useState } from "react";
import "./Navber.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../../assets/images/iwms_logo_bg.jpg";
import Logo1 from "../../assets/images/Assist_Logo.svg";
import VietnamActiveIcon from "../../assets/icons/LoginIcons/vietnam_active.svg";
import VietnamInactiveIcon from "../../assets/icons/LoginIcons/vietnam_inactive.svg";
import UKActiveIcon from "../../assets/icons/LoginIcons/unitedkingdom_active.svg";
import UKInactiveIcon from "../../assets/icons/LoginIcons/unitedkingdom_inactive.svg";
import MenuBannerImage from "../../assets/images/menu_banner.png";
import { BsArrowDownRight, BsArrowRight, BsCheck } from "react-icons/bs";
import {
  BiRightArrow,
  BiCalendar,
  BiPlusCircle,
  BiRightArrowAlt,
} from "react-icons/bi";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
  FormSelect,
  ButtonGroup,
  Container,
  Button,
} from "reactstrap";
import {
  initLanguage,
  translateLanguage,
  checkedLanguagesKey,
  languageEnglish,
  languageVietnamese,
  defaultLanguage,
  allLanguages,
} from "../../services/CommonUtil";
import { RxCheck } from "react-icons/rx";
import ToggleSwitch from "../../component/ToggleSwitch";

class MrfMenuNavbar extends Component {
  constructor(props) {
    super(props);
    var locisCheckedLanguage = localStorage.getItem("isCheckedLanguage");
    if (locisCheckedLanguage === null || locisCheckedLanguage === undefined) {
      locisCheckedLanguage = defaultLanguage === languageEnglish;
      localStorage.setItem("isCheckedLanguage", locisCheckedLanguage);
    }
    var locpathname = "";
    try {
      locpathname = window.location.hash.split("?")[0];
    } catch (e) {}
    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
      isCheckedLanguage: locisCheckedLanguage === "true" ? true : false,
      pathname: locpathname,
      allLanguages: null,
      fromLogin: this.props.isLogin,
      isHomeActive: locpathname === "#/home" ? true : false,
      isNewsActive: false,
      isInsAndFormActive: false,
      isContactActive: false,
    };
    initLanguage(function () {
      console.log("Languages->", allLanguages);
    });
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.updateWindowDimensions());
    this.setState({
      isHomeActive: this.state.pathname === "#/home" ? true : false,
      isNewsActive: this.state.pathname === "#/NewsList" ? true : false,
      isInsAndFormActive:
        this.state.pathname === "#/RecyclingMonitoring" ? true : false,
      isContactActive: this.state.pathname === "#/Contact" ? true : false,
    });
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  Fn_CheckedLanguage = () => {
    console.log("Mrf Menu On Change Clicked");
    // var checked = document.getElementById("rad_lang1").checked;
    // this.setState({ isCheckedLanguage: checked });
    // localStorage.setItem("isCheckedLanguage", checked);
    // console.log(this.state.isCheckedLanguage);
    // console.log(checked);
    // setTimeout(function () {
    //   initLanguage(function () {
    //     window.location.reload();
    //   });
    // }, 100);
  };
  Fn_WindowReload = () => {
    setTimeout(function () {
      window.location.reload();
    }, 0);
  };
  // Hide or show the menu.
  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
      screenWidth: null,
    });
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  };

  Fn_Logout = () => {
    localStorage.clear();

    //window.location.href = "/#/login";
    setTimeout(function () {
      window.location.href = "/";
    }, 1000);
  };

  Fn_LoginLogout = () => {
    if (
      localStorage.getItem("isLogin") === undefined ||
      localStorage.getItem("isLogin") === "false"
    ) {
      setTimeout(function () {
        window.location.href = "/#/login";
      }, 10);
    } else {
      localStorage.clear();
      localStorage.setItem("isLogin", "false");
      //window.location.href = "/#/login";
      setTimeout(function () {
        window.location.href = "/";
      }, 1000);
    }
  };

  updateWindowDimensions() {
    this.setState({ screenWidth: window.innerWidth });
  }
  handleLanguageChange = () => {
    const { isCheckedLanguage } = this.state;
    const newCheckedLanguage = !isCheckedLanguage;
    this.setState({ isCheckedLanguage: newCheckedLanguage });
    localStorage.setItem("isCheckedLanguage", newCheckedLanguage);
  };

  handleToggleSwitchChage = (e) => {
    var checked = e.target.checked;
    // console.log("Checked->", checked);
    this.setState({ isCheckedLanguage: checked });
    localStorage.setItem("isCheckedLanguage", checked);
    // console.log(this.state.isCheckedLanguage);
    // console.log(checked);
    setTimeout(function () {
      initLanguage(function () {
        window.location.reload();
      });
    }, 100);
  };

  render() {
    const { isCheckedLanguage } = this.state;
    return (
      <div className="container-fluid p-0">
        <Navbar className="col-md-12" bg="primary " text="white" expand="lg">
          <Nav className="col-12">
            <div class="col-12 topnav d-flex">
              <Col md={3} className="desktop_language">
                <Row>
                  <div className="menu-language-switch">
                    {allLanguages.map((language, index) => (
                      <>
                        {language.languageText +
                          (language.languageId === languageVietnamese
                            ? " | "
                            : "")}
                      </>
                    ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {allLanguages.map((language, index) => (
                        <>
                          {language.languageId === languageEnglish ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                // gap: "3px",
                              }}
                            >
                              {/* <Button
                                color="transparent !important"
                              >
                                <Input
                                  id="rad_lang1"
                                  type="radio"
                                  name="radio2"
                                  checked={this.state.isCheckedLanguage}
                                  onChange={this.Fn_CheckedLanguage}
                                />
                                {this.state.isCheckedLanguage && (
                                  <RxCheck className="radio_check" size="25" />
                                )}
                              </Button>  */}
                              <ToggleSwitch
                                label=" "
                                onChange={(e) =>
                                  this.handleToggleSwitchChage(e)
                                }
                                isCheckedLanguage={this.state.isCheckedLanguage}
                              />
                              {/* <h6 className="lang_swtich">EN</h6> */}

                              <img
                                width={30}
                                height={30}
                                src={UKActiveIcon}
                                alt=""
                                className="rounded-img mx-auto d-block"
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: "10px",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <img
                                width={30}
                                height={30}
                                src={VietnamActiveIcon}
                                alt=""
                                className="rounded mx-auto d-block"
                              />
                              {/* <h6 className="lang_swtich">VN</h6> */}

                              {/* <Button
                                color="transparent !important"
                              // className="btn-pill btn btn-warning"
                              >
                                <Input
                                  id="rad_lang2"
                                  type="radio"
                                  name="radio2"
                                  checked={!this.state.isCheckedLanguage}
                                  onChange={this.Fn_CheckedLanguage}
                                />
                                {!this.state.isCheckedLanguage && (
                                  <RxCheck className="radio_check" size="25" />
                                )}
                              </Button> */}
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </Row>
              </Col>

              <Col md={7} className="log_name">
                <div className="d-flex justify-content-center">
                  <img
                    width={60}
                    height={60}
                    src={Logo}
                    alt=""
                    className="rounded image-btn d-block"
                  />
                  <div className="d-flex justify-content-center ">
                    <h3 className="myHeading mt-3 text-white text-center">
                      {translateLanguage(
                        checkedLanguagesKey,
                        "clean_city_blue_ocean"
                      )}
                    </h3>
                  </div>

                  <img
                    width={60}
                    height={60}
                    src={Logo1}
                    alt=""
                    className="rounded image-btn d-block"
                  />
                </div>
              </Col>

              {/* <Col md={2} className="d-flex justify-content-center">
                <button type="button" class="btn btn-outline-dark">Logout</button>
              </Col> */}
            </div>
          </Nav>
        </Navbar>
        {this.state.fromLogin ? (
          <></>
        ) : (
          <div>
            <Navbar
              className="text-center"
              bg="secondary"
              text="primary"
              expand="lg"
            >
              <Col md={3} className="mobile_language">
                <Row>
                  <div className="menu-language-switch">
                    {allLanguages.map((language, index) => (
                      <>
                        {language.languageText +
                          (language.languageId === languageVietnamese
                            ? " | "
                            : "")}
                      </>
                    ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {allLanguages.map((language, index) => (
                        <>
                          {language.languageId === languageEnglish ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                // gap: "3px",
                              }}
                            >
                              {/* <Button
                                color="transparent !important"
                              >
                                <Input
                                  id="rad_lang1"
                                  type="radio"
                                  name="radio2"
                                  checked={this.state.isCheckedLanguage}
                                  onChange={this.Fn_CheckedLanguage}
                                />
                                {this.state.isCheckedLanguage && (
                                  <RxCheck className="radio_check" size="25" />
                                )}
                              </Button>  */}
                              <ToggleSwitch
                                label=" "
                                onChange={(e) =>
                                  this.handleToggleSwitchChage(e)
                                }
                                isCheckedLanguage={this.state.isCheckedLanguage}
                              />
                              {/* <h6 className="lang_swtich text-blue">EN</h6> */}
                              <img
                                width={30}
                                height={30}
                                src={UKActiveIcon}
                                alt=""
                                className="rounded-img mx-auto d-block"
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: "10px",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <img
                                width={30}
                                height={30}
                                src={VietnamActiveIcon}
                                alt=""
                                className="rounded mx-auto d-block"
                              />
                              {/* <h6 className="lang_swtich text-red">VN</h6> */}

                              {/* <Button
                                color="transparent !important"
                              // className="btn-pill btn btn-warning"
                              >
                                <Input
                                  id="rad_lang2"
                                  type="radio"
                                  name="radio2"
                                  checked={!this.state.isCheckedLanguage}
                                  onChange={this.Fn_CheckedLanguage}
                                />
                                {!this.state.isCheckedLanguage && (
                                  <RxCheck className="radio_check" size="25" />
                                )}
                              </Button> */}
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </Row>
              </Col>

              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="col-md-12">
                  <NavDropdown
                    title={translateLanguage(checkedLanguagesKey, "home")}
                    id="basic-nav-dropdown"
                    className="col-3"
                    active={this.state.isHomeActive}
                  >
                    <NavDropdown.Item href="/#/MRFLogin">
                      <div className="row">
                        <div className="col-3">
                          {this.state.pathname === "#/MRFLogin" ||
                          this.state.pathname === "#/MRFLogin" ? (
                            <BsCheck size={25} color="white" />
                          ) : (
                            ""
                          )}
                          {translateLanguage(
                            checkedLanguagesKey,
                            "lc_peoples_committee_option"
                          )}
                        </div>
                      </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/#/MRFLogin">
                      <div className="row">
                        <div className="col-3">
                          {this.state.pathname === "#/MRFLogin" ||
                          this.state.pathname === "#/MRFLogin" ? (
                            <BsCheck size={25} color="white" />
                          ) : (
                            ""
                          )}
                          {translateLanguage(
                            checkedLanguagesKey,
                            "mrf_station_option"
                          )}
                        </div>
                      </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/#/MRFLogin">
                      <div className="row">
                        <div className="col-3">
                          {this.state.pathname === "#/MRFLogin" ||
                          this.state.pathname === "#/MRFLogin" ? (
                            <BsCheck size={25} color="white" />
                          ) : (
                            ""
                          )}

                          {translateLanguage(
                            checkedLanguagesKey,
                            "urenco_15_option"
                          )}
                        </div>
                      </div>
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link
                    className="col-3"
                    href="/#/NewsList"
                    active={this.state.isNewsActive}
                    // onClick={this.Fn_WindowReload}
                  >
                    {translateLanguage(checkedLanguagesKey, "news")}
                  </Nav.Link>
                  <Nav.Link
                    className="col-3"
                    href="/#/InstructionsandForm"
                    active={this.state.isInsAndFormActive}
                    // onClick={this.Fn_WindowReload}
                  >
                    {translateLanguage(checkedLanguagesKey, "instruct")}
                  </Nav.Link>
                  <Nav.Link
                    className="col-3"
                    href="/#/Contact"
                    active={this.state.isContactActive}
                    // onClick={this.Fn_WindowReload}
                  >
                    {translateLanguage(checkedLanguagesKey, "contact")}
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        )}
      </div>
    );
  }
}

export default MrfMenuNavbar;
