import React, { Component, Suspense } from "react";
import {
  Route,
  BrowserRouter as Router,
  HashRouter,
  Routes,
  Navigate,
} from "react-router-dom";
import "./css/App.css";
import "./css/Custom.css";
import Footer from "./component/Footer";
import QRCodeList from "./component/Master/QRCodeList";
import UserCreationList from "./component/Master/UserCreationList";
import MRFStationList from "./component/Master/MRFStationList";
// import Contactdetails from "./component/Master/Contactdetails";
// import QRCode from "./component/Master/QRCode";
// import MRFStation from "./component/Master/MRFStation";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

/*
import About from "./component/About/About";
import Address from "./component/Address/Address";
import Tricycle from "./component/Tricycle/Tricycle";
import Truck from "./component/Truck/Truck";
import Login from "./component/Login/Login";
import ForgetPassword from "./component/Login/ForgetPassword";
import UserManagement from "./component/Admin/UserManagement";
function App() {
  return (
    <div className="App">
      <Router>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgetpassword" component={ForgetPassword} />
        <Route exact path="/about" component={About} />
        <Route exact path="/address" component={Address} />
        <Route exact path="/tricycle" component={Tricycle} />
        <Route exact path="/truck" component={Truck} />
        <Route exact path="/usermanagement" component={UserManagement} />
      </Router>
    </div>
  );
}
*/

const Login = React.lazy(() => import("./component/Login/Login"));
const Address = React.lazy(() => import("./component/Address/Address"));
const AddressList = React.lazy(() => import("./component/Address/AddressList"));
const Tricycle = React.lazy(() => import("./component/Tricycle/Tricycle"));
const TricycleList = React.lazy(() =>
  import("./component/Tricycle/TricycleList")
);
const TruckList = React.lazy(() => import("./component/Truck/TruckList"));
const UserList = React.lazy(() => import("./component/Admin/UserList"));
const Truck = React.lazy(() => import("./component/Truck/Truck"));
const WasteCollectionPoints = React.lazy(() =>
  import("./component/Dashboard/WastePoints")
);
const CollectionPoints = React.lazy(() =>
  import("./component/Dashboard/CollectionPoints")
);
const Recycling = React.lazy(() => import("./component/Apartment/Recycling"));
const OFMLeaves = React.lazy(() => import("./component/Apartment/OFM-Leaves"));
const OFMLO = React.lazy(() => import("./component/Apartment/OFM-LO"));
const RecyclingMonitoring = React.lazy(() =>
  import("./component/MonitoringTable/RecyclingMonitoring")
);
const OFLeavesMonitoring = React.lazy(() =>
  import("./component/MonitoringTable/OFM-LeavesMonitoring")
);
const OLOMonitoring = React.lazy(() =>
  import("./component/MonitoringTable/OFM-LOMonitoring")
);
const Apartment = React.lazy(() =>
  import("./component/OperationalManual/Apartment")
);
const School = React.lazy(() => import("./component/OperationalManual/School"));
const JunkShop = React.lazy(() =>
  import("./component/OperationalManual/JunkShop")
);
const WasteCollectionTruck = React.lazy(() =>
  import("./component/Dashboard/WasteTruck")
);
const WasteCollectionTricycle = React.lazy(() =>
  import("./component/Dashboard/WasteTricycle")
);
const AnalyticsReports = React.lazy(() =>
  import("./component/AnalyticsReports/Analytics")
);
const ForgotPassword = React.lazy(() =>
  import("./component/Login/ForgotPassword")
);
const UserManagement = React.lazy(() =>
  import("./component/Admin/UserManagement")
);
const Country = React.lazy(() => import("./component/Admin/Country"));
const Province = React.lazy(() => import("./component/Admin/Province"));
const City = React.lazy(() => import("./component/Admin/City"));
const PostalCode = React.lazy(() => import("./component/Admin/PostalCode"));
const About = React.lazy(() => import("./component/About/About"));
const Home = React.lazy(() => import("./component/Home/Home"));
const HomeNew = React.lazy(() => import("./component/Home/Home_new"));
const NewsDetailPage = React.lazy(() => import("./component/News/News"));
const InstructionsAndForm = React.lazy(() =>
  import("./component/InstructionsAndForm/InstructionsAndForm")
);
const LCPCLoginPage = React.lazy(() =>
  import("./component/LCPCLogin/LCPCLoginPage")
);
const LCPCLandingPage = React.lazy(() =>
  import("./component/LCPCLandingPage/LCPCLandingPage")
);
const MRFStation = React.lazy(() => import("./component/Master/MRFStation"));
const QRCode = React.lazy(() => import("./component/Master/QRCode"));
const UserCreation = React.lazy(() =>
  import("./component/Master/UserCreation")
);
const Newsmaster = React.lazy(() => import("./component/Master/Newsmaster"));
const NewsmasterList = React.lazy(() =>
  import("./component/Master/NewsmasterList")
);
const InstructForm = React.lazy(() =>
  import("./component/Master/InstructForm")
);
const Contactdetails = React.lazy(() =>
  import("./component/Master/Contactdetails")
);
const InstructFormList = React.lazy(() =>
  import("./component/Master/InstructFormList")
);
const MRFLoginPage = React.lazy(() =>
  import("./component/MRFLoginPage/MRFLoginPage")
);
const MRFDashboard = React.lazy(() =>
  import("./component/MRFDashboard/MRFDashboard")
);
const MRFWasteCollection = React.lazy(() =>
  import("./component/MRFWasteCollection/MRFWasteCollection")
);
const MRFInfo = React.lazy(() => import("./component/MRFInfo/MRFInfo"));
const UrencoLoginPage = React.lazy(() =>
  import("./component/UrencoLoginPage/UrencoLoginPage")
);
const UrencoDashboard = React.lazy(() =>
  import("./component/UrencoDashboard/UrencoDashboard")
);
const UrencoQRCodeScan = React.lazy(() =>
  import("./component/UrencoQRCodeScan/UrencoQRCodeScan")
);
const UrencoInfo = React.lazy(() =>
  import("./component/UrencoInfo/UrencoInfo")
);

const QRScanResult = React.lazy(() =>
  import("./component/UrencoQRCodeScan/QRScanResult")
);
const MRFInfoResult = React.lazy(() =>
  import("./component/MRFInfo/MRFInfoResult")
);
const LCPCDashboard = React.lazy(() =>
  import("./component/LCPCDashboard/LCPCDashboard")
);
const LCPCReports = React.lazy(() =>
  import("./component/LCPCReports/LCPCReports")
);
const Contacts = React.lazy(() => import("./component/Contacts/Contacts"));
const NewsList = React.lazy(() => import("./component/News/NewsList"));
class App extends Component {
  render() {
    return (
      <div className="App">
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/" name="Login Page" element={<Login />} />
              <Route
                exact
                path="/login"
                name="Login Page"
                element={<Login />}
              />
              {/*
                <Route
                exact
                path="/address"
                name="Address Page"
                element={<Address />}
              />
              <Route
                exact
                path="/addresslist"
                name="AddressList Page"
                element={<AddressList />}
              />
              <Route
                exact
                path="/tricycle"
                name="Tricycle Page"
                element={<Tricycle />}
              />
              <Route
                exact
                path="/tricyclelist"
                name="TricycleList Page"
                element={<TricycleList />}
              />
              <Route
                exact
                path="/truck"
                name="Truck Page"
                element={<Truck />}
              />
              <Route
                exact
                path="/trucklist"
                name="TruckList Page"
                element={<TruckList />}
              />
                */}
              <Route
                exact
                path="/forgotpassword"
                name="ForgotPassword Page"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/usermanagement"
                name="UserManagement Page"
                element={<UserManagement />}
              />
              <Route
                exact
                path="/userlist"
                name="UserList Page"
                element={<UserList />}
              />
              <Route
                exact
                path="/about"
                name="About Page"
                element={<About />}
              />
              <Route
                exact
                path="/wastepoints"
                name="Waste Collection Points"
                element={<WasteCollectionPoints />}
              />
              <Route
                exact
                path="/collectionpoints"
                name="Collection Points"
                element={<CollectionPoints />}
              />
              <Route
                exact
                path="/Recycling"
                name="Recycling"
                element={<Recycling />}
              />
              <Route
                exact
                path="/OFM-Leaves"
                name="OFMLeaves"
                element={<OFMLeaves />}
              />
              <Route exact path="/OFM-LO" name="OFMLO" element={<OFMLO />} />
              <Route
                exact
                path="/JunkShop"
                name="JunkShop"
                element={<JunkShop />}
              />
              <Route
                exact
                path="/Apartment"
                name="Apartment"
                element={<Apartment />}
              />
              <Route exact path="/School" name="School" element={<School />} />
              <Route
                exact
                path="/RecyclingMonitoring"
                name="RecyclingMonitoring"
                element={<RecyclingMonitoring />}
              />
              <Route
                exact
                path="/OFM-LeavesMonitoring"
                name="OFLeavesMonitoring"
                element={<OFLeavesMonitoring />}
              />
              <Route
                exact
                path="/OFM-LOMonitoring"
                name="OLOMonitoring"
                element={<OLOMonitoring />}
              />
              <Route
                exact
                path="/wastetruck"
                name="Waste Collection Truck"
                element={<WasteCollectionTruck />}
              />
              <Route
                exact
                path="/wastetricycle"
                name="Waste Collection Tricycle"
                element={<WasteCollectionTricycle />}
              />
              <Route
                exact
                path="/BuySell"
                name="Buy/Sell"
                element={<AnalyticsReports />}
              />
              <Route
                exact
                path="/country"
                name="Country"
                element={<Country />}
              />
              <Route
                exact
                path="/province"
                name="Province"
                element={<Province />}
              />
              <Route exact path="/city" name="City" element={<City />} />
              <Route
                exact
                path="/postalcode"
                name="Postal Code"
                element={<PostalCode />}
              />
              <Route exact path="/home" name="Home Page" element={<Home />} />
              <Route exact path="/Home_new" name="HomeNew" element={<HomeNew />} />
              <Route
                exact
                path="/NewsDetails"
                name="News"
                element={<NewsDetailPage />}
              />
              <Route
                exact
                path="/InstructionsandForm"
                element={<InstructionsAndForm />}
              />
              <Route exact path="/LCPCLogin" element={<LCPCLoginPage />} />
              <Route exact path="/LCPCLanding" element={<LCPCLandingPage />} />
              <Route exact path="/MRFStation" element={<MRFStation />} />
              <Route
                exact
                path="/MRFStationList"
                element={<MRFStationList />}
              />
              <Route exact path="/QRCode" element={<QRCode />} />
              <Route exact path="/QRCodeList" element={<QRCodeList />} />
              <Route exact path="/MRFLogin" element={<MRFLoginPage />} />
              <Route exact path="/UserCreation" element={<UserCreation />} />
              <Route
                exact
                path="/UserCreationList"
                element={<UserCreationList />}
              />
              <Route exact path="/Newsmaster" element={<Newsmaster />} />
              <Route
                exact
                path="/NewsmasterList"
                element={<NewsmasterList />}
              />
              <Route exact path="/InstructForm" element={<InstructForm />} />
              <Route
                exact
                path="/Contactdetails"
                element={<Contactdetails />}
              />
              <Route
                exact
                path="/InstructFormList"
                element={<InstructFormList />}
              />
              <Route exact path="/MRFDashboard" element={<MRFDashboard />} />
              <Route
                exact
                path="/MRFWasteCollection"
                element={<MRFWasteCollection />}
              />
              <Route exact path="/MRFInfo" element={<MRFInfo />} />
              <Route exact path="/UrencoLogin" element={<UrencoLoginPage />} />
              <Route
                exact
                path="/UrencoDashboard"
                element={<UrencoDashboard />}
              />
              <Route
                exact
                path="/UrencoQRCodeScan"
                element={<UrencoQRCodeScan />}
              />
              <Route exact path="/UrencoInfo" element={<UrencoInfo />} />
              <Route exact path="/QRResultPage" element={<QRScanResult />} />
              <Route exact path="/MRFInfoResult" element={<MRFInfoResult />} />
              <Route exact path="/LCPCDashboard" element={<LCPCDashboard />} />
              <Route exact path="/LCPCReports" element={<LCPCReports />} />
              <Route exact path="/Contact" element={<Contacts />} />
              <Route exact path="/NewsList" element={<NewsList />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </div>
    );
  }
}

export default App;
