import React, { useEffect, useRef, useState } from "react";

import {
  apiConfigurationsGetAllLanguages,
  apiConfigurationsGetAllLanguageKeyActive,
} from "./MicroService";

export const languageEnglish = 1;
export const languageVietnamese = 2;
export const defaultLanguage = languageEnglish;
export var allLanguages = eval(localStorage.getItem("allLanguages"));
export var allLanguagesKey = JSON.parse(
  localStorage.getItem("allLanguagesKey")
);
export var checkedLanguagesKey = JSON.parse(
  localStorage.getItem("checkedLanguagesKey")
);
export var isCheckedLanguage = localStorage.getItem("isCheckedLanguage");

export const customGlobal = {
  PAGE_UNAUTHORIZED_ACCESS: "/401",
  PAGE_NOT_FOUND: "/404",
};

export const defaultPageRights = {
  isCreate: false,
  isDelete: false,
  isEdit: false,
  isView: false,
};

export const configRole = {
  ADMIN: 1,
  HR: 9,
  PM: 4,
  QAM: 6,
};

export const defaultLanguageKeyValue = {
  1: {
    waste_collection_points: "Waste Collection Points",
    waste_collection_trucks: "Waste Collection Trucks",
    waste_collection_tricycle: "Waste Collection Tricycle",
    total_number_of_waste_collection_points:
      "Total Number of Waste collection points",
    total_number_of_waste_collection_points_covered:
      "Total Number of Waste collection points covered",
    total_waste_collected: "Total Waste collected",
    total_Waste_recycled: "Total Waste Recycled",
    total_waste_landfill: "Total Waste Landfill",
    total_number_of_trips: "Total Number of Trips",
    total_number_of_trips_covered: "Total Number of Trips Covered",
    tons: "Tons",
    working_in_progress: "Work in Progress",
    forgot_password: "Forgot Password",
    forget_password: "Forget Password",
    entered_user_details: "User Details",
    rows_per_page: "Rows per page",
    postal_code: "Postal Code",
    city: "City",
    country_list: "Country List",
    create_new_country: "Create New Country",
    country_master: "Country Master",
    country_name: "Country Name",
    province_list: "Province List",
    create_new_province: "Create New Province",
    province_master: "Province Master",
    province_name: "Province Name",
    city_list: "City List",
    create_new_city: "Create New City",
    city_master: "City Master",
    city_name: "City Name",
    postalcode_list: "PostalCode List",
    create_new_postalcode: "Create New PostalCode",
    postalcode_master: "PostalCode Master",
    postalcode: "PostalCode",
    edit_country: "Edit Country",
    update: "Update",
    view_country: "View Country",
    are_you_sure: "Are you sure",
    kindly_select_any_country: "Kindly Select any Country",
    country_created_successfully: "Country Created Successfully",
    select: "Select",
    sno: "S.No",
    kindly_select_any_province: "Kindly Select any Province",
    province_created_successfully: "Province Created Successfully",
    view_province: "View Province",
    edit_province: "Edit Province",
    province_updated_successfully: "Province Updated Successfully",
    kindly_select_any_city: "Kindly Select any City",
    city_created_successfully: "City Created Successfully",
    view_city: "View City",
    edit_city: "Edit City",
    city_updated_successfully: "City Updated Successfully",
    kindly_select_any_postalcode: "Kindly Select any PostalCode",
    postalcode_created_successfully: "PostalCode Created Successfully",
    MRFStation_created_successfully: "MRF Station Created Successfully",
    MRFStation_updated_successfully: "MRF Station Details Updated Successfully",
    view_postalcode: "View PostalCode",
    edit_postalcode: "Edit PostalCode",
    postalcode_updated_successfully: "PostalCode Updated Successfully",
    do_you_want_to_delete_the_country: "Do You Want to Delete the Country",
    do_you_want_to_delete_the_city: "Do You Want to Delete the City",
    do_you_want_to_delete_the_province: "Do You Want to Delete the Province",
    do_you_want_to_delete_the_postalcode:
      "Do You Want to Delete the Postalcode",
    country_updated_successfully: "Country Updated Successfully",
    kindly_enter_6_digit_postalcode: "Kindly Enter 6 Digit Postalcode",
    kindly_enter_the_country_name: "Kindly Enter The Country Name",
    kindly_enter_the_provine_name: "Kindly Enter The Province Name",
    kindly_enter_the_city_name: "Kindly Enter The City Name",
    kindly_enter_the_postalcode: "Kindly Enter The Postalcode",
    country_already_exists: "Country Already Exists",
    unknowm_failed: "Unknowm Failed",
    city_already_exists: "City Already Exists",
    postalcode_already_exists: "Postalcode Already Exists",
    province_already_exists: "Province Already Exists",
    unknown_failed: "Unknown Failed",
    qr_code_generation_for_collection_point:
      "QR code generation for collection point",
    qr_code_generation_list: "List of Collection Points with QR code ",
    QR_Details_entered: "Create New Collection Point",
    Unique_ID: "Unique ID",
    entered__QR: "Entered QR Code",
    MRF_Station_list: "List of MRF Stations",
    MRFStation_Details_entered: "MRF Station Details",
    MRFStation_Details: "Create New MRF Station",
    UserCreation_title: "User Creation",
    UserUpdate_title: "Update User Details",
    UserCreation_type: "User role type",
    UserCreation_Number: "Contact Number",
    UserCreation_Name: "Contact Person Name",
    MRFType_Name: "MRF Name",
    UserMrf_type: "Type of MRF",
    building_no: "Building No",
    Name_of_the_station: "Name of the MRF station",
    NewsCreation_Name: "News Creation",
    NewsCreation_List: "List of News Creation",
    Instruction_Name: "Instructions and Form",
    Instruction_List: "Instructions and Form List",
    News_listpage: "Back to List Page",
    submit: "Submit",
    enter_the_txt_name: "Kindly enter the MRF station name",
    enter_the_txt_type: "Kindly select  the  MRF type",
    enter_the_txt_building: "Kindly enter  the  Building no",
    enter_the_contact_name: "Kindly enter the contact person name",
    enter_the_contact_number: "Kindly enter the contact number",
    enter_the_collection_point: "Kindly select the collection point type",
    enter_the_Unique_Id: "Kindly enter the unique id",
    enter_the_latitude: "Kindly enter the latitude",
    enter_the_longitude: "Kindly enter the longitude",
    latitude: "Lat",
    longitude: "Long",
    address: "Address",
    unique_ID: "Unique ID",
    collection_point: "Type of Collection",
    alert: "Alert",
    Contacts: "Contacts",
    menutitle: "Menu Title",
    newstitle: "News Title",
    newsphoto: "News Photo",
    document_upload: "Document Upload",
    newscontent: "News Content",
    head_editorial_board: "Head of the Editorial Board",
    mrf_station: "Create New MRF Station",
    contact_name: "Contact Name",
    mrf_photo: "MRF Photo",
    type_of_collection: "Type of Collection Point",
    contact_person_name: "Contact Person Name",
    dashboard: "Dashboard",
    reports: "Reports",
    configure: "Configure Masters",
    mrf: "MRF Station",
    qrcode: "QR code for Collection Point",
    news: "News",
    instruct: "Instructions and Forms",
    manage: "User Management",
    contact: "Contact",
    /* Manikandan - 410222 - 19 Feb 2024 */
    home: "Home",
    clean_city_title: "Clean Cities,",
    blue_ocean_title: "Blue Ocean.",
    clean_city_blue_ocean: "Clean Cities, Blue Ocean",
    search_mrf_in_district: "Search the MRF locations in Liên Chiểu District",
    enter_mrf_name: "Enter the MRF Name",
    lc_peoples_committee_option: "LC people's committee",
    mrf_station_option: "MRF Station",
    urenco_15_option: "URENCO 15",
    recycle_waste_monitor_side_option:
      "Recycling Waste Collection and Monitoring",
    leaves_monitor_side_option: "Organic Fertilizer Monitoring (Leaves)",
    leftovers_monitor_side_option: "Organic Fertilizer Monitoring (Leftovers)",
    junkshops_side_option: "Junk Shops",
    apartment_side_option: "Apartment",
    school_side_option: "School",
    login_title: "Log In",
    username_title: "Username",
    username_placeholder: "Enter username",
    password_title: "Password",
    password_placeholder: "Enter password",
    bottom_wastecollection_title: "Waste Collection",
    bottom_info_title: "Info",
    type_of_waste_title: "Type of Waste",
    volume_of_waste_title: "Volume of Waste",
    enter_volume_placeholder: "Enter Volume",
    amount_collected_title: "Amount Collected",
    enter_amount_placeholder: "Enter Amount",
    add_btn_title: "Add",
    wastecollection_report_title: "Waste Collection Report",
    mrf_info_option: "MRF Information",
    logout_title: "Logout",
    address_line1_title: "Address Line 1",
    address_line2_title: "Address Line 2",
    latitude_title: "Latitude",
    longitude_title: "Longitude",
    cancel: "Cancel",
    qr_scan_title: "QR Scan",
    create_new_btn_title: "Create New",
    dropdown_select_title: "Select",
    back_to_list_title: "Back To List",
    kindly_enter_role_type: "Kindly select the User Role",
    kindly_enter_news_title: "Kindly enter the News Title",
    kindly_enter_news_desc: "Kindly enter the News Content",
    news_details_header_title: "News Details",
    print_btn_title: "Print",
    export_excel_btn_title: "Export as Excel",
    export_pdf_btn_title: "Export as PDF",
    instructions_form_details: "Instructions and Form Details",
    kindy_select_menu_title: "Kindly select Menu Title",
    read_more_btn_title: "Read More",
    update_collection_details_title: "Update Collection Point Details",
    view_collection_details_title: "Collection Point Details",
    update_mrf_station_title: "Update MRF Station Details",
    kindly_select_mrf_station: "Kindly select any MRF Station",
    kindly_select_collection_point: "Kindly select any Collection Point",
    kindly_select_any_news: "Kindly select any News",
    update_news_details_title: "Update News Details",
    waste_collection_reports: "Waste Collection Reports",
    apply_btn_title: "Apply",
    reset_btn_title: "Reset",
    monthly_collection_report: "Monthly Waste Colletion Report",
    download_report:"Download Report",
    mrf_information:"MRF Information"
  },
  2: {
    waste_collection_points: "Điểm thu gom rác thải",
    waste_collection_trucks: "Xe thu gom rác thải",
    waste_collection_tricycle: "Xe ba bánh thu gom rác thải",
    total_number_of_waste_collection_points: "Tổng số điểm thu gom rác",
    total_number_of_waste_collection_points_covered:
      "Tổng số điểm thu gom rác thải",
    total_waste_collected: "Tổng lượng rác thải được thu gom",
    total_Waste_recycled: "Tổng lượng rác thải được tái chế",
    total_waste_landfill: "Tổng lượng rác thải chôn lấp",
    total_number_of_trips: "Tổng số chuyến đi",
    total_number_of_trips_covered: "Tổng số chuyến đi đã thực hiện",
    tons: "Tấn",
    working_in_progress: "đang làm việc",
    forgot_password: "Quên mật khẩu",
    forget_password: "Quên mật khẩu",
    entered_user_details: "Thông tin người dùng đã nhập",
    rows_per_page: "Hàng trên mỗi trang",
    postal_code: "Mã bưu điện",
    city: "Thành phố",
    country_list: "Danh sách quốc gia",
    create_new_country: "Tạo quốc gia mới",
    country_master: "Quốc sư",
    country_name: "Tên quốc gia",
    province_list: "Danh sách tỉnh",
    create_new_province: "Tạo tỉnh mới",
    province_master: "tỉnh trưởng",
    province_name: "Tên tỉnh",
    postalcode_list: "Danh sách mã bưu chính",
    create_new_postalcode: "Tạo Mã Bưu Chính Mới",
    postalcode_master: "Bậc thầy mã bưu điện",
    city_list: "Danh sách thành phố",
    postalcode: "Mã bưu điện",
    create_new_city: "Tạo thành phố mới",
    city_master: "chủ thành phố  ",
    city_name: "Tên thành phố",
    edit_country: "Chỉnh sửa quốc gia",
    update: "Cập nhật",
    view_country: "Xem quốc gia",
    are_you_sure: "Bạn có chắc không",
    kindly_select_any_country: "Vui lòng chọn bất kỳ quốc gia nào",
    country_created_successfully: "Quốc gia được tạo thành công",
    select: "Chọn",
    sno: "S.Không",
    kindly_select_any_province: "Vui lòng chọn bất kỳ tỉnh nào",
    province_created_successfully: "Tỉnh được tạo thành công",
    view_province: "Xem tỉnh",
    edit_province: "Chỉnh sửa tỉnh",
    province_updated_successfully: "Tỉnh được cập nhật thành công",
    kindly_select_any_city: "Vui lòng chọn bất kỳ thành phố nào",
    city_created_successfully: "Thành phố được tạo thành công",
    view_city: "Xem thành phố",
    edit_city: "Chỉnh sửa thành phố",
    city_updated_successfully: "Thành phố được cập nhật thành công",
    kindly_select_any_postalcode: "Vui lòng chọn bất kỳ Mã bưu chính nào",
    postalcode_created_successfully: "Mã bưu điện được tạo thành công",
    view_postalcode: "Xem Mã Bưu Chính",
    edit_postalcode: "Chỉnh sửa mã bưu điện",
    postalcode_updated_successfully: "Mã bưu chính được cập nhật thành công",
    do_you_want_to_delete_the_country: "Bạn có muốn xóa quốc gia không",
    do_you_want_to_delete_the_city: "Bạn có muốn xóa thành phố",
    do_you_want_to_delete_the_province: "Bạn có muốn xóa tỉnh",
    do_you_want_to_delete_the_postalcode: "Bạn có muốn xóa mã bưu chính không",
    country_updated_successfully: "Quốc gia được cập nhật thành công",
    kindly_enter_6_digit_postalcode: "Vui lòng nhập Mã bưu điện 6 chữ số",
    kindly_enter_the_country_name: "vui lòng nhập tên quốc gia",
    kindly_enter_the_provine_name: "Vui lòng nhập tên tỉnh",
    kindly_enter_the_city_name: "Vui lòng nhập tên thành phố",
    kindly_enter_the_postalcode: "Vui lòng nhập mã bưu điện",
    country_already_exists: "Quốc gia đã tồn tại",
    city_already_exists: "Thành phố đã tồn tại",
    postalcode_already_exists: "Mã bưu chính đã tồn tại",
    province_already_exists: "Tỉnh đã tồn tại",
    unknown_failed: "Không xác định Không thành công",
    Unique_ID: "Unique ID",
    qr_code_generation_list: "Danh sách Điểm thu thập có mã QR",
    QR_Details_entered: "Tạo điểm thu thập mới",
    MRF_Station_list: "Danh sách các trạm MRF",
    entered__QR: "Đã nhập mã QR",
    MRFStation_Details_entered: "MRF Station Details",
    MRFStation_Details: "Tạo trạm MRF mới",
    UserCreation_type: "Loại vai trò người dùng",
    UserMrf_type: "Loại MRF",
    UserCreation_Number: "Số liên lạc",
    MRFType_Name: "Tên MRF",
    UserCreation_Name: "Tên người liên lạc",
    Name_of_the_station: "Tên trạm MRF",
    NewsCreation_Name: "Tạo tin tức",
    NewsCreation_List: "Danh sách tạo tin tức",
    Instruction_Name: "Hướng dẫn và biểu mẫu",
    Instruction_List: "Hướng dẫn và Danh sách Biểu mẫu",
    News_listpage: "Quay lại trang danh sách",
    submit: "Nộp",
    MRFStation_created_successfully: "Trạm MRF được tạo thành công",
    MRFStation_updated_successfully:
      "Chi tiết trạm MRF được cập nhật thành công",
    enter_the_txt_name: "Vui lòng nhập tên trạm MRF",
    enter_the_txt_type: "Vui lòng chọn loại MRF",
    enter_the_txt_building: "Vui lòng vào Tòa nhà số",
    enter_the_contact_name: "Vui lòng nhập tên người liên hệ",
    enter_the_contact_number: "Vui lòng nhập số liên lạc",
    address: "Địa chỉ",
    enter_the_latitude: "Vui lòng nhập vĩ độ",
    enter_the_longitude: "Vui lòng nhập kinh độ",
    unique_ID: "ID duy nhất",
    collection_point: "Loại bộ sưu tập",
    enter_the_collection_point: "Vui lòng chọn loại điểm thu thập",
    enter_the_Unique_Id: "Vui lòng nhập id duy nhất",
    alert: "Báo động",
    menutitle: "Tiêu đề thực đơn",
    newstitle: "Tiêu đề tin tức",
    newscontent: "Nội dung tin tức",
    newsphoto: "Ảnh tin tức",
    document_upload: "Tải lên tài liệu",
    head_editorial_board: "Trưởng ban biên tập",
    Contacts: "Liên lạc",
    building_no: "Tòa nhà số",
    mrf_station: "Tạo trạm MRF mới",
    contact_name: "Tên Liên lạc",
    latitude: "vĩ độ",
    longitude: "kinh độ",
    mrf_photo: "Ảnh MRF",
    type_of_collection: "Loại điểm thu gom",
    contact_person_name: "Tên người liên lạc",
    UserCreation_title: "Tạo người dùng",
    dashboard: "Bảng điều khiển",
    reports: "Báo cáo",
    configure: "Cấu hình Masters",
    mrf: "Trạm MRF",
    qrcode: "Mã QR cho Điểm thu gom",
    news: "Tin tức",
    instruct: "Hướng dẫn và Biểu mẫu",
    manage: "Quản lý người dùng",
    contact: "Sự tiếp xúc",
    /* Manikandan - 410222 - 19 Feb 2024 */
    home: "Trang chủ",
    clean_city_title: "Thành phố sạch,",
    blue_ocean_title: "Đại dương xanh.",
    clean_city_blue_ocean: "Thành Phố Sạch, Đại Dương Xanh",
    search_mrf_in_district: "Tìm kiếm các địa điểm MRF tại huyện Liên Chiểu",
    enter_mrf_name: "Nhập tên MRF",
    lc_peoples_committee_option: "Ủy ban nhân dân LC",
    mrf_station_option: "Trạm MRF",
    urenco_15_option: "URENCO 15",
    recycle_waste_monitor_side_option: "Tái chế thu thập và giám sát chất thải",
    leaves_monitor_side_option: "Giám sát phân bón hữu cơ (Lá)",
    leftovers_monitor_side_option: "Giám sát phân bón hữu cơ (thức ăn thừa)",
    junkshops_side_option: "Cửa hàng rác",
    apartment_side_option: "Căn hộ",
    school_side_option: "Trường học",
    login_title: "Đăng nhập",
    username_title: "tên tài khoản",
    username_placeholder: "Điền tên đăng nhập",
    password_title: "Mật khẩu",
    password_placeholder: "Nhập mật khẩu",
    bottom_wastecollection_title: "Thu gom rác thải",
    bottom_info_title: "Thông tin",
    type_of_waste_title: "Loại chất thải",
    volume_of_waste_title: "Khối lượng chất thải",
    enter_volume_placeholder: "Nhập âm lượng",
    amount_collected_title: "Số tiền thu được",
    enter_amount_placeholder: "Nhập số tiền",
    add_btn_title: "Thêm vào",
    wastecollection_report_title: "Báo cáo thu gom rác thải",
    mrf_info_option: "Thông tin MRF",
    logout_title: "Đăng xuất",
    address_line1_title: "Dòng địa chỉ 1",
    address_line2_title: "Dòng địa chỉ 2",
    latitude_title: "Vĩ độ",
    longitude_title: "Kinh độ",
    cancel: "Hủy bỏ",
    qr_scan_title: "Quét QR",
    create_new_btn_title: "Tạo mới",
    dropdown_select_title: "Lựa chọn",
    back_to_list_title: "Trở lại danh sách",
    kindly_enter_role_type: "Vui lòng chọn Vai trò người dùng",
    kindly_enter_news_title: "Vui lòng nhập Tiêu đề tin tức",
    kindly_enter_news_desc: "Vui lòng nhập Nội dung tin tức",
    news_details_header_title: "Chi tiết tin tức",
    print_btn_title: "In",
    export_excel_btn_title: "Xuất dưới dạng Excel",
    export_pdf_btn_title: "Xuất dưới dạng PDF",
    instructions_form_details: "Hướng dẫn và chi tiết biểu mẫu",
    kindy_select_menu_title: "Vui lòng chọn Tiêu đề thực đơn",
    read_more_btn_title: "Đọc thêm",
    update_collection_details_title: "Cập nhật chi tiết điểm thu thập",
    view_collection_details_title: "Chi tiết điểm thu thập",
    update_mrf_station_title: "Cập nhật chi tiết trạm MRF",
    kindly_select_mrf_station: "Vui lòng chọn bất kỳ trạm MRF nào",
    kindly_select_collection_point: "Vui lòng chọn bất kỳ Điểm nhận hàng nào",
    kindly_select_any_news: "Vui lòng chọn bất kỳ Tin tức nào",
    update_news_details_title: "Cập nhật chi tiết tin tức",
    apply_btn_title: "Áp dụng",
    reset_btn_title: "Cài lại",
    monthly_collection_report: "Báo cáo thu gom rác thải hàng tháng",
    download_report:"Tải xuống báo cáo",
    mrf_information:"Thông tin MRF",
  },
};
export const printDivContent = (QRText) => {
  // const content = document.getElementById(divId).innerHTML;
  var LangQRText = translateLanguage(checkedLanguagesKey, "qr_code_no");
  const printWindow = window.open("", "_blank");
  printWindow.document.write("<html><head><title>Print</title></head><body>");
  printWindow.document.write(
    '<html><head><title></title> </head> <body style="font-family:Arial, Helvetica, sans-serif; font-size: 20px; font-weight: bold;"><div> <img src="https://chart.googleapis.com/chart?cht=qr&amp;chl=' +
      QRText +
      '&amp;chs=280x280&amp;chld=L|0" style="display: block; align-items: center; margin: 20px auto; margin-top: 150px;"></div><div style="text-align: center; margin-top:20px; font-size: 30px;"><label>' +
      LangQRText +
      " : </label><label>" +
      QRText +
      "</label></div></body></html>"
  );
  printWindow.document.write("</body></html>");
  printWindow.document.close();
  printWindow.print();
};

export const printDivContentNew = (divId) => {
  const content = document.getElementById(divId).innerHTML;
  const printWindow = window.open("", "_blank");
  printWindow.document.write("<html><head><title>Print</title></head><body>");
  printWindow.document.write(
    '<html><head><title></title> </head> <body style="font-family:Arial, Helvetica, sans-serif; font-size: 20px; font-weight: bold;">' +
      content +
      "</body></html>"
  );
  printWindow.document.write("</body></html>");
  printWindow.document.close();
  printWindow.print();
};

export const greetingsMessage = () => {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return "Good Morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
};

export const toCamelCase = (value) => {
  return value
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const toSnakeCase = (value) => {
  const sentenceCaseText = value
    .replace(/_/g, " ")
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );

  return sentenceCaseText;
};

export const getPageRights = (menus, menuEnumScreen) => {
  var resultObj = {
    isCreate: false,
    isDelete: false,
    isEdit: false,
    isView: false,
  };
  return resultObj;
};

export function useInterval(callback, delay) {
  // Creating a ref
  const savedCallback = useRef();

  // To remember the latest callback .
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // combining the setInterval and
  //clearInterval methods based on delay.
  useEffect(() => {
    function func() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(func, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const initLanguage = (callback) => {
  Fn_apiConfigurationsGetAllLanguages();
  Fn_apiConfigurationsGetAllLanguageKeyActive();
  Fn_checkedLanguagesKeyUpdate(callback);
};

const Fn_checkedLanguagesKeyUpdate = (callback) => {
  if (allLanguagesKey !== null) {
    var isCheckedLanguage = localStorage.getItem("isCheckedLanguage");
    var loc_checkedLanguagesKey = {};
    var content = allLanguagesKey;
    var contentLen = content.length;
    var checkedLanguageConfigLanguageId =
      isCheckedLanguage === "true" ? languageEnglish : languageVietnamese;
    for (var i = 0; i < contentLen; i++) {
      var contentObj = content[i];
      var languageConfigKey = contentObj.languageConfigKey;
      var languageConfigLanguageId = contentObj.languageConfigLanguageId;
      var languageConfigValue = contentObj.languageConfigValue;

      if (languageConfigLanguageId === checkedLanguageConfigLanguageId) {
        // console.log("languageConfigKey");
        //console.log(languageConfigKey);

        //console.log("languageConfigValue");
        //console.log(languageConfigValue);
        loc_checkedLanguagesKey[languageConfigKey.trim()] = languageConfigValue;
      }
    }

    console.log("Common loc_checkedLanguagesKey");
    console.log(loc_checkedLanguagesKey);
    loc_checkedLanguagesKey = {
      ...loc_checkedLanguagesKey,
      ...defaultLanguageKeyValue[checkedLanguageConfigLanguageId],
    };
    checkedLanguagesKey = loc_checkedLanguagesKey;
    localStorage.setItem(
      "checkedLanguagesKey",
      JSON.stringify(checkedLanguagesKey)
    );

    if (typeof callback !== "undefined") {
      callback();
    }
  }
};

const Fn_apiConfigurationsGetAllLanguageKeyActive = () => {
  if (allLanguagesKey === null && checkedLanguagesKey === null) {
    apiConfigurationsGetAllLanguageKeyActive()
      .then((response) => {
        console.log("Common apiConfigurationsGetAllLanguageKeyActive response");
        console.log(response);
        var isCheckedLanguage = localStorage.getItem("isCheckedLanguage");
        var loc_checkedLanguagesKey = {};
        if (response.status === 200) {
          var dataObj = response.data;
          console.log(dataObj);
          var content = dataObj.content;
          var contentLen = content.length;
          var checkedLanguageConfigLanguageId =
            isCheckedLanguage === "true" ? languageEnglish : languageVietnamese;
          console.log("contentLen");
          console.log(contentLen);

          for (var i = 0; i < contentLen; i++) {
            var contentObj = content[i];
            var languageConfigKey = contentObj.languageConfigKey;
            var languageConfigLanguageId = contentObj.languageConfigLanguageId;
            var languageConfigValue = contentObj.languageConfigValue;

            if (languageConfigLanguageId === checkedLanguageConfigLanguageId) {
              // console.log("languageConfigKey");
              //console.log(languageConfigKey);

              //console.log("languageConfigValue");
              //console.log(languageConfigValue);
              loc_checkedLanguagesKey[languageConfigKey.trim()] =
                languageConfigValue;
            }
          }

          console.log("Common loc_checkedLanguagesKey");
          console.log(loc_checkedLanguagesKey);
          console.log("Common dataObj");
          console.log(dataObj);
          allLanguagesKey = dataObj.content;
          console.log(
            "defaultLanguageKeyValue[checkedLanguageConfigLanguageId]"
          );
          console.log(defaultLanguageKeyValue[checkedLanguageConfigLanguageId]);
          console.log("Common checkedLanguageConfigLanguageId");
          console.log(checkedLanguageConfigLanguageId);
          loc_checkedLanguagesKey = {
            ...loc_checkedLanguagesKey,
            ...defaultLanguageKeyValue[checkedLanguageConfigLanguageId],
          };
          checkedLanguagesKey = loc_checkedLanguagesKey;
          localStorage.setItem(
            "allLanguagesKey",
            JSON.stringify(allLanguagesKey)
          );
          localStorage.setItem(
            "checkedLanguagesKey",
            JSON.stringify(checkedLanguagesKey)
          );
          window.location.reload();
        }
      })
      .catch((err) => {
        // setAlertmessage(err.response.data.message)
      });
  }
};

const Fn_apiConfigurationsGetAllLanguages = () => {
  if (allLanguages === null) {
    apiConfigurationsGetAllLanguages()
      .then((response) => {
        console.log("Common apiConfigurationsGetAllLanguages response");
        console.log(response);
        if (response.status === 200) {
          var dataObj = response.data;
          console.log("setAllLanguages");
          console.log(dataObj);
          allLanguages = dataObj.content;
          localStorage.setItem("allLanguages", JSON.stringify(allLanguages));
        }
      })
      .catch((err) => {
        // setAlertmessage(err.response.data.message)
      });
  }
};

export const translateLanguage1 = (key) => {
  var langStr = "NO DATA";
  if (
    checkedLanguagesKey === undefined ||
    checkedLanguagesKey === "" ||
    checkedLanguagesKey === null
  ) {
    return langStr;
  } else {
    if (
      checkedLanguagesKey[key] === undefined ||
      checkedLanguagesKey[key] === "" ||
      checkedLanguagesKey[key] === null
    ) {
      return langStr;
    } else {
      return checkedLanguagesKey[key];
    }
  }
};

export const translateLanguage = (checkedLanguagesKey, key) => {
  var langStr = "***";
  if (
    checkedLanguagesKey === undefined ||
    checkedLanguagesKey === "" ||
    checkedLanguagesKey === null
  ) {
    return langStr;
  } else {
    if (
      checkedLanguagesKey[key] === undefined ||
      checkedLanguagesKey[key] === "" ||
      checkedLanguagesKey[key] === null
    ) {
      return langStr;
    } else {
      return checkedLanguagesKey[key];
    }
  }
};
