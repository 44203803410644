import React, { useState, useEffect } from "react";
import Navber from "../Navber/Navber";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Table } from "react-bootstrap";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
  FormSelect,
  ButtonGroup,
  Button,
  Container,
} from "reactstrap";
import {
  initLanguage,
  translateLanguage,
  checkedLanguagesKey,
  languageEnglish,
  languageVietnamese,
  defaultLanguage,
} from "../../services/CommonUtil";
import {
  apiRegistrationGetAllUserById,
  apiRegistrationGetAllMRFUserById,
  apiMRFUserManagementsDeleteUserRegistrations,
  apiRegistrationDeleteMRFUserRegistrations,
  apiRegistrationDeleteUserRegistrations,
  apiRegistrationGetAllMRFUserRegistrationsActive,
  apiRegistrationGetAllUserRegistrationsActive,
} from "../../services/MicroService";
import LCPCNavbar from "../LCPCNavBar/LCPCNavbar";
const ts = (key) => {
  return translateLanguage(checkedLanguagesKey, key);
};
const header = [
  { title: ts("select"), prop: "select" },
  { title: ts("s_no"), prop: "sno" },
  { title: ts("name_of_the_user"), prop: "mrfUserRegistrationName" },
  { title: ts("role"), prop: "mrfUserRoleName" },
  { title: ts("email_address"), prop: "mrfUserRegistrationEmail" },
  { title: ts("phone_number"), prop: "mrfUserRegistrationMobileNo" },
];

const UserCreationList = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const Fn_screenTranslateLanguageUpdate = () => {
    setScreenTranslateLanguage({});
  };
  const navigate = useNavigate();
  const [allUserList, setAllUserList] = useState([]);
  const [checkedUserID, setCheckedUserID] = useState(null);
  const [screenTranslateLanguage, setScreenTranslateLanguage] = useState({});
  const sAlert = (msg) => {
    setShowAlert(true);
    setAlertMessage(ts(msg));
  };
  const sConfirm = (msg) => {
    setShowConfirm(true);
    setConfirmMessage(ts(msg));
  };
  useEffect(() => {
    Fn_apiRegistrationGetAllUserRegistrationsActive();
    // apiRegistrationGetAllMRFUserRegistrationsActive();
  }, []);

  useEffect(() => {
    initLanguage(function () {
      Fn_screenTranslateLanguageUpdate();
    });
  }, []);

  const Fn_getElementById = (id) => {
    console.log(id);
    return document.getElementById(id).value;
  };

  const Fn_setElementById = (id, value) => {
    document.getElementById(id).innerText = value;
  };

  const Fn_setElementByIdValue = (id, value) => {
    document.getElementById(id).value = value;
  };

  const Fn_validateEmpty = (value) => {
    return value == undefined || value == null || value == "";
  };
  const Fn_CheckedUser = (e) => {
    console.log(e);
    setCheckedUserID(e.target.value);
  };

  const Fn_apiRegistrationDeleteRegistrations = (urnId) => {
    apiMRFUserManagementsDeleteUserRegistrations(urnId)
      .then((response) => {
        console.log("apiRegistrationDeleteUserRegistrations response");
        console.log(response);
        if (response.status == 200) {
          var dataObj = response.data;
          var content = dataObj.content;
          window.location.reload();
        }
      })
      .catch((err) => {
        // setAlertmessage(err.response.data.message)
      });
  };

  const Fn_apiRegistrationGetAllUserRegistrationsActive = () => {
    apiRegistrationGetAllMRFUserRegistrationsActive()
      .then((response) => {
        console.log("Fn_apiRegistrationGetAllUserRegistrationsActive response");
        console.log(response);
        if (response.status == 200) {
          var dataObj = response.data;

          var content = dataObj.content;
          var contentLen = content.length;

          for (var i = 0; i < contentLen; i++) {
            var userRegistrationId = content[i].mrfUserRegistrationId;
            var sno = i + 1;
            content[i]["sno"] = i + 1;
            content[i]["select"] = (
              <Input
                id={"rad_usertype_" + sno}
                type="radio"
                name="usertype"
                value={userRegistrationId}
                onClick={Fn_CheckedUser}
              />
            );
          }
          setAllUserList(content);
        }
      })
      .catch((err) => {
        // setAlertmessage(err.response.data.message)
      });
  };

  const Fn_CancelUser = () => {
    Fn_setElementByIdValue("txt_user_name", "");
    Fn_setElementByIdValue("txt_user_email", "");
    Fn_setElementByIdValue("text_user_phone_no", "");
    Fn_setElementByIdValue("text_user_role", "");
    Fn_setElementByIdValue("text_user_password", "");
  };

  const Fn_ViewUser = () => {
    if (checkedUserID == null) {
      sAlert("kindly_select_any_user");
      return false;
    }
    // sAlert("Not Implemented");
    navigate("/userCreation?checkedUserID=" + checkedUserID + "&mode=view");
  };

  const Fn_EditUser = () => {
    if (checkedUserID == null) {
      sAlert("kindly_select_any_user");
      return false;
    }
    navigate("/userCreation?checkedUserID=" + checkedUserID);
  };

  const Fn_DeleteUser = () => {
    if (checkedUserID == null) {
      sAlert("kindly_select_any_user");
      return false;
    }
    Fn_apiRegistrationDeleteRegistrations(checkedUserID);
  };

  const Fn_DeleteUser_Confirm = () => {
    Fn_DeleteUser();
  };

  const Fn_PrintUser = () => {
    if (checkedUserID == null) {
      sAlert("kindly_select_any_User");
      return false;
    }
    Fn_GetAllUserRegistrationById(checkedUserID);
  };
  const Fn_GetAllUserRegistrationById = (mrfUserRegistrationId) => {
    apiRegistrationGetAllMRFUserById(mrfUserRegistrationId)
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          var dataObj = response.data;

          var content = dataObj.content[0];

          var content = dataObj.content[0];
          console.log(content);
        }
      })
      .catch((err) => {
        // setAlertmessage(err.response.data.message)
      });
  };
  const Fn_GoToCreateUser = () => {
    navigate("/userCreation");
  };
  // render() {
  return (
    <div>
      <LCPCNavbar />
      <div md="12" className="mt-4">
        <Container fluid>
      
        <Col xs={12}>
        <div className="p-2">
              <div className="row">
              <div className="col-md-6">
              <h4 className="bold mb-4">
                    {translateLanguage(checkedLanguagesKey, "user_list")}
                  </h4>
                </div>
                <div className="col-md-6 d-flex justify-content-end align-items-center">
                  <Button
                    className="mb-2 me-2 side pull-right"
                    active
                    color="primary1"
                    onClick={Fn_GoToCreateUser}
                  >
                    {translateLanguage(checkedLanguagesKey, "create")}
                  </Button>
                  
                  <Button
                    className="mb-2 me-2 side pull-right"
                    active
                    color="info"
                    onClick={Fn_ViewUser}
                  >
                    {translateLanguage(checkedLanguagesKey, "view")}
                  </Button>
                  <Button
                    className="mb-2 me-2 side pull-right"
                    active
                    color="primary1"
                    onClick={Fn_EditUser}
                  >
                    {translateLanguage(checkedLanguagesKey, "edit")}
                  </Button>
                  <Button
                    className="mb-2 me-2 btn-danger pull-right"
                    active
                    color="cancel1"
                    onClick={() => {
                      sConfirm("do_you_want_to_delete_the_record");
                    }}
                  >
                    {translateLanguage(checkedLanguagesKey, "delete")}
                  </Button>
                </div>
              </div>
           </div>
           <Card className="table-card">
                <CardBody>
                  <div className="home mb-2">
                    <Col md={12}>
                  <DatatableWrapper
                    body={allUserList}
                    headers={header}
                    checkboxProps={{
                      onCheckboxChange: function noRefCheck() {},
                    }}
                    paginationOptionsProps={{
                      initialState: {
                        // rowsPerPage: 5,
                        // options: [5, 10, 15, 20],
                        rowsPerPage: 25,
                        options: [25, 50, 75, 100],
                      },
                    }}
                  >
                    <Row>
                      <Col
                        xs={12}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                      >
                        <Filter />
                      </Col>
                      <Col xs={12} sm={8} className="d-none">
                        <div class="pull-right">
                          <PaginationOptions />
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                      ></Col>
                    </Row>
                    <div className="table-responsive">
                    <Table>
                      <TableHeader />
                      <TableBody />
                    </Table>
                    </div>
                    <div class="pull-right">
                      <Pagination
                        labels={{
                          firstPage: "<<",
                          lastPage: ">>",
                          prevPage: "<",
                          nextPage: ">",
                        }}
                      />
                    </div>
                  </DatatableWrapper>
                  </Col>
                  </div>
                </CardBody>
              </Card>
           
          </Col>
        </Container>
      </div>
      {alertMessage != "" ? (
        <SweetAlert
          title={translateLanguage(checkedLanguagesKey, "alert")}
          show={showAlert}
          confirmBtnText={ts("ok")}
          onConfirm={() => {
            setShowAlert(false);
          }}
          onCancel={() => {
            return true;
          }}
        >
          {alertMessage}
        </SweetAlert>
      ) : (
        ""
      )}
      {confirmMessage != "" ? (
        <SweetAlert
          warning
          showCancel
          show={showConfirm}
          confirmBtnText={ts("delete")}
          cancelBtnText={ts("cancel")}
          okBtnText={ts("ok")}
          confirmBtnBsStyle="danger"
          title={ts("are_you_sure")}
          onConfirm={() => {
            Fn_DeleteUser_Confirm();
            setShowConfirm(false);
          }}
          onCancel={() => {
            setShowConfirm(false);
          }}
        >
          {confirmMessage}
        </SweetAlert>
      ) : (
        ""
      )}
    </div>
  );
};
// }

export default UserCreationList;
